import api from '@/services/api';

const url = 'internship-day';

const create = date => api.post(`${url}/create-internship-day`, date);

const readById = id => api.get(`${url}/read-internship-day/${id}`);

const remove = id => api.delete(`${url}/delete-internship-day/${id}`);

export default { create, remove, readById };
