<template>
  <div>
    <simple-list subject="professores" serviceName="supervisor" />
  </div>
</template>

<script>
import SimpleList from '../../../components/List/SimpleList.vue';

export default {
  name: 'ListSupervisor',
  components: { SimpleList },
};
</script>

<style scoped></style>
