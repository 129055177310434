<template>
  <v-data-table
    :headers="headers"
    :items="fields"
    class="elevation-1"
    disable-sort
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Adicionar Dia</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark class="mb-2" v-bind="attrs" v-on="on">
              Adicionar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Adicionar Data</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <p>Inserir Datas Aqui</p>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Data"
                      v-mask="`##/##/####`"
                      v-model="typedDate"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Você tem certeza que deseja deletar este item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Deletar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>

    <template v-slot:[`item.date`]="{ item }">
      <span>{{ item.date | date }}</span>
    </template>
  </v-data-table>
</template>

<script>
import dayjs from 'dayjs';
import internshipDayService from '../../services/internship-day';

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    typedDate: '',
    headers: [
      {
        text: 'Datas',
        align: 'start',
        value: 'date',
      },
      { text: 'Excluir', align: 'center', value: 'actions', sortable: false },
    ],
    fields: [],
    editedIndex: -1,
  }),

  props: {
    internshipId: {
      type: Number,
      required: true,
      default: () => -1,
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const { data } = await internshipDayService.readById(this.internshipId);
        this.fields = data;
      } catch (error) {
        console.error(error);
      }
    },

    deleteItem(item) {
      this.editedIndex = this.fields.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        await internshipDayService.remove(this.editedItem.id);
        this.initialize();
        this.closeDelete();
      } catch (error) {
        console.error(error);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const myDateSplitted = this.typedDate.split('/');

      const convertedDate = dayjs(
        `${myDateSplitted[2]}/${myDateSplitted[1]}/${myDateSplitted[0]}`,
      ).toISOString();

      const internshipDayPayload = {
        date: convertedDate,
        internship: {
          id: this.internshipId,
        },
      };

      try {
        await internshipDayService.create(internshipDayPayload);
        this.successToast('Salvo com Sucesso!');
        this.initialize();
      } catch (error) {
        this.errorToast('Erro ao Salvar');
        console.error(error);
      }

      this.close();
    },
  },
};
</script>
