// coment
<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column align-center">
      <v-img :src="require('../assets/logo.png')" contain />
    </div>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>

<style scoped></style>
