var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.propsHeaders,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","to":{ name: (_vm.subject + ".cadastro") }}},[_vm._v(" Adicionar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showDetails)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.viewItem(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showPrintOption)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.printDocument(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-printer ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Imprimir")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showDelete)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.preventDefault();return _vm.prepareToDelete(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.preventDefault();return _vm.editItem(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-edit")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(_vm.serviceName == 'sector' && item.inactive == false)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.preventDefault();return _vm.inactiveActive(item.id, true)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("Inativar")])])],1):(_vm.serviceName == 'sector' && item.inactive == true)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.serviceName == 'sector' && item.inactive == true)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.preventDefault();return _vm.inactiveActive(item.id, false)}}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}:null],null,true)},[_c('span',[_vm._v("Ativar")])])],1):_vm._e(),(
              _vm.serviceName == 'supervisor' && item.person.inactive == false
            )?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.preventDefault();return _vm.inactiveActive(item.person.id, true)}}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v("Ativar")])])],1):(
              _vm.serviceName == 'supervisor' && item.person.inactive == true
            )?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){$event.preventDefault();return _vm.inactiveActive(item.person.id, false)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("Inativar")])])],1):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"pa-5"},[(_vm.visible)?_c('more-details',{attrs:{"dialog":_vm.visible,"id":_vm.subjectId,"serviceName":_vm.serviceName},on:{"close":function($event){_vm.visible = !_vm.visible}}}):_vm._e(),_c('delete-confirmation',{attrs:{"visible":_vm.dialogDelete},on:{"cancel":function($event){_vm.dialogDelete = false},"confirm":function($event){return _vm.deleteItem(_vm.ServiceUser.deleteOne)}}}),(_vm.visibleEdit)?_c('edit-form',{attrs:{"visible":_vm.visibleEdit,"dialog":_vm.visibleEdit,"id":_vm.subjectId,"serviceName":_vm.serviceName},on:{"close":function($event){_vm.visibleEdit = !_vm.visibleEdit}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }