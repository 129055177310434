<template>
  <div>
    <h2 class="text-uppercase font-weight-light mb-5">Acadêmicos</h2>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Academic',
};
</script>

<style scoped></style>
