<template>
  <v-list dense>
    <v-list-item
      v-for="item in menu"
      :key="item.path"
      link
      class="py-2"
      :to="`/${item.path}`"
    >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-bold">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { appRoutes } from '@/router/routes/app';

export default {
  name: 'MenuItems',
  computed: {
    menu() {
      return appRoutes[0].children.map(({ meta, path }) => ({
        icon: meta.icon,
        title: meta.title,
        path,
      }));
    },
  },
};
</script>

<style scoped></style>
