import { isValidCPF, isValidCEP } from '@brazilian-utils/brazilian-utils';

export const formRules = {
  required: v => v?.length > 0 || 'Este campo é obrigatório',
  requiredSelect: v => !!v || 'Necessário Selecionar um Item',
  requiredFile: v => !!v || 'Necessário Selecionar um Arquivo',
  email: v => /.+@.+/.test(v) || 'E-mail inválido',
  validCpf: v => isValidCPF(String(v).replace(/[^0-9]/g, '')) || 'CPF inválido',
  validCep: v => isValidCEP(String(v).replace(/\D/g, '')) || 'CEP inválido',
};
