<template>
  <base-form ref="baseForm" :fields="fields" form-name="sector">
    <template v-slot="{ field }">
      <div v-if="!field.select">
        <div v-if="field.mask">
          <v-text-field
            :label="field.label"
            v-model="sectorLocal[field.prop]"
            v-mask="field.mask"
            :counter="field.maxLength || false"
            :maxLength="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            outlined
          />
        </div>
        <div v-else>
          <v-text-field
            :label="field.label"
            v-model="sectorLocal[field.prop]"
            :maxLength="field.maxLength || false"
            :rules="field.rules"
            :counter="field.maxLength || false"
            :readonly="isViewOnlyMode"
            outlined
          />
        </div>
      </div>
      <div v-else>
        <v-select
          :items="field.item"
          v-model="sectorLocal[field.prop]"
          :label="field.label"
          :rules="field.rules"
          :readonly="isViewOnlyMode"
          outlined
        ></v-select>
      </div>
    </template>
  </base-form>
</template>

<script>
import BaseForm from '@/components/Form/BaseForm';
import internshipFieldsEnum from '@/enums/internshipFieldEnum';
export default {
  name: 'SectorForm',
  components: { BaseForm },
  props: {
    sector: {
      type: Object,
      default: () => {},
    },
    editForm: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    sectorLocal: {
      get() {
        return this.sector;
      },
      set(valor) {
        this.$emit('update:sector', valor);
      },
    },
  },
  data() {
    return {
      isViewOnlyMode: false,
      fields: [
        {
          label: 'Titulo - Setor',
          prop: 'title',
          rules: [this.$rules.required],
          maxLength: 100,
          cols: '6',
        },
        {
          label: 'Área do Setor',
          prop: 'sectorField',
          item: internshipFieldsEnum,
          rules: [this.$rules.requiredSelect],
          maxLength: 100,
          select: true,
          cols: '6',
        },
        {
          label: 'Quatidade de Alunos',
          prop: 'maxAcademics',
          rules: [this.$rules.required],
          mask: '##',
          cols: '6',
        },
        {
          label: 'Descrição',
          prop: 'description',
          rules: [this.$rules.required],
          maxLength: 500,
          cols: '6',
        },
      ],
    };
  },
  created() {
    setTimeout(() => {
      // !this is for partial bug fix in view mode
      if (this.sector.id) {
        this.isViewOnlyMode = !this.editForm;
        this.sectorLocal.maxAcademics = `${this.sectorLocal.maxAcademics}`;
      }
    }, 100);
  },
  methods: {
    requestBaseFormValidation() {
      return this.$refs.baseForm.validateBaseForm();
    },
  },
};
</script>
<style scoped></style>
