<template>
  <base-form ref="baseForm" :fields="fields" form-name="academic">
    <template v-slot="{ field }">
      <div v-if="!field.select">
        <div v-if="field.mask">
          <v-text-field
            :label="field.label"
            v-model="supervisorLocal[field.prop]"
            v-mask="field.mask"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            @input="uppercase(field.prop, $event)"
            outlined
          />
        </div>
        <div v-else>
          <v-text-field
            :label="field.label"
            v-model="supervisorLocal[field.prop]"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            @input="uppercase(field.prop, $event)"
            outlined
          />
        </div>
      </div>
      <div v-else>
        <v-select
          :items="field.item"
          v-model="supervisorLocal[field.prop]"
          :label="field.label"
          :maxLength="field.maxLength || false"
          :counter="field.maxLength || false"
          :rules="field.rules"
          :readonly="isViewOnlyMode"
          outlined
        ></v-select>
      </div>
    </template>
  </base-form>
</template>

<script>
import BaseForm from '@/components/Form/BaseForm';
import internshipFieldsEnum from '@/enums/internshipFieldEnum';
import workingPeriodFieldEnum from '@/enums/workingPeriodFieldEnum';
import courseFieldEnum from '@/enums/courseFieldEnum';
import semesterFieldEnum from '@/enums/semesterFieldEnum';
import dayjs from 'dayjs';
import institutionFieldEnum from '@/enums/institutionFieldEnum';

export default {
  name: 'SupervisorForm',
  components: { BaseForm },
  props: {
    supervisor: {
      type: Object,
      default: () => {},
    },
    editForm: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    supervisorLocal: {
      get() {
        return this.supervisor;
      },
      set(valor) {
        this.$emit('update:supervisor', valor);
      },
    },
  },
  data() {
    return {
      isViewOnlyMode: false,
      fields: [
        {
          label: 'Curso',
          prop: 'course',
          item: courseFieldEnum,
          rules: [this.$rules.required],
          select: true,
          cols: '6',
          maxLength: 150,
        },
        {
          label: 'Disciplina',
          prop: 'subject',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 150,
        },
        {
          label: 'Semestre',
          prop: 'semester',
          item: semesterFieldEnum,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '2',
        },
        {
          label: 'Período de Atuação',
          prop: 'workingPeriod',
          item: workingPeriodFieldEnum,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '2',
        },
        {
          label: 'Instituição',
          prop: 'institution',
          rules: [this.$rules.required],
          item: institutionFieldEnum,
          select: true,
          cols: '8',
          maxLength: 150,
        },
        {
          label: 'Área do Estágio',
          prop: 'internshipField',
          item: internshipFieldsEnum,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '6',
        },
        {
          label: 'Carga Horária',
          prop: 'workingHours',
          rules: [this.$rules.required],
          mask: '####',
          cols: '3',
        },
        {
          label: 'Semestre do Estágio',
          prop: 'internshipSemester',
          item: ['Primeiro semestre', 'Segundo semestre'],
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '3',
        },
        {
          label: 'Data Inicial',
          prop: 'startDate',
          mask: '##/##/####',
          rules: [this.$rules.required],
          cols: '6',
        },
        {
          label: 'Data Final',
          prop: 'endDate',
          mask: '##/##/####',
          rules: [this.$rules.required],
          cols: '6',
        },
      ],
    };
  },

  async created() {
    if (this.supervisor.id) {
      this.isViewOnlyMode = !this.editForm;
    }
    if (this.supervisorLocal.startDate || this.supervisorLocal.endDate) {
      this.supervisorLocal.startDate = dayjs(
        this.supervisorLocal.startDate,
      ).format('DD/MM/YYYY');
      this.supervisorLocal.endDate = dayjs(this.supervisorLocal.endDate).format(
        'DD/MM/YYYY',
      );
    }
  },

  methods: {
    requestBaseFormValidation() {
      return this.$refs.baseForm.validateBaseForm();
    },
    uppercase(prop, e) {
      this.supervisorLocal[prop] = e.toUpperCase();
    },
  },
};
</script>

<style scoped></style>
