<template>
  <div>
    <simple-list
      subject="setores"
      serviceName="sector"
      :propsHeaders="headers"
    />
  </div>
</template>

<script>
import SimpleList from '../../../components/List/SimpleList.vue';

export default {
  name: 'ListSector',
  components: { SimpleList },

  data: () => ({
    headers: [
      { text: 'Título', value: 'title' },
      { text: 'Área do Setor', value: 'sectorField' },
      { text: 'Quantidade de Alunos', value: 'maxAcademics' },
      { text: 'Ações', value: 'actions' },
    ],
  }),
};
</script>

<style scoped></style>
