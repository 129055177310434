<template>
  <div>
    <v-navigation-drawer v-model="drawer" color="grey lighten-3" app>
      <menu-items />
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-spacer></v-spacer>
      <v-btn color="transparent" outlined @click="logout">
        <v-icon color="white">mdi-logout</v-icon>
        <p class="white--text mt-4 ml-5">Deslogar</p>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MenuItems from '@/components/Menu/MenuItems';
export default {
  name: 'AppLayout',
  components: { MenuItems },
  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({ name: 'auth.login' });
    },
  },
};
</script>

<style scoped></style>
