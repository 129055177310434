const internshipMixin = {
  data() {
    return {
      visible: false,
      subjectId: '',
      items: [],
      fields: [],
      sectorOptions: [],
      supervisorOptions: [],
    };
  },

  methods: {
    async findItems(findMethod) {
      try {
        const { data } = await findMethod();
        this.items = data;
      } catch (e) {
        console.error(e);
      }
    },

    async findFields(findMethod) {
      try {
        const { data } = await findMethod;
        this.fields = data;
      } catch (e) {
        console.error(e);
      }
    },

    async findSector(findMethod) {
      try {
        const { data } = await findMethod();
        this.sectorOptions = data.filter(sector => {
          return sector.inactive !== true
        }).map(v =>{
          return {title:v.title, id:v.id}
        });
      } catch (e) {
        console.error(e);
      }
    },

    async findSupervisor(findMethod) {
      try {
        const { data } = await findMethod();
        this.supervisorOptions = data.filter(supervisor => {
          return supervisor.person.inactive !== true;
        }).map(v => {
          return { title: v.person.name, id: v.id }
        });
      } catch (e) {
        console.error(e);
      }
    },

    editItem(id) {
      this.subjectId = id;
      this.visible = !this.visible;
    },
  },
};

export default internshipMixin;
