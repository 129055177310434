<template>
  <div>
    <v-dialog :value="showDialogAfterLoad" fullscreen>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Lista de Detalhes</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card>
        <div
          class="pa-5"
          v-if="serviceName === 'academic' || serviceName === 'supervisor'"
        >
          <h2 class="text-uppercase font-weight-light mb-5">Dados pessoais</h2>
          <person-form :person.sync="fields.person" :isEditing="false" />

          <h2 class="text-uppercase font-weight-light mb-5">Endereço</h2>
          <address-form :address="fields.person.address" />
        </div>

        <div class="pa-5" v-if="serviceName === 'academic'">
          <h2 class="text-uppercase font-weight-light mb-5">
            Dados Acadêmicos
          </h2>
          <academic-form :academic.sync="fields" />
        </div>

        <div class="pa-5" v-if="serviceName === 'supervisor'">
          <h2 class="text-uppercase font-weight-light mb-5">
            Dados do Estágio
          </h2>
          <supervisor-form :supervisor.sync="fields" />
        </div>

        <div class="pa-5" v-if="serviceName === 'sector'">
          <h2 class="text-uppercase font-weight-light mb-5">Dados do Setor</h2>
          <sector-form :sector.sync="fields" />
        </div>

        <v-simple-table
          class="pa-5"
          v-if="serviceName === 'academic' || serviceName === 'supervisor'"
          dense
        >
          <template v-slot:default>
            <h2 class="text-uppercase font-weight-light mb-5">Arquivos</h2>
            <v-row no-gutters>
              <v-col v-for="(val, name, index) in fields.attch" :key="index">
                <v-card class="d-flex pa-2 justify-center" flat>
                  <v-btn
                    outlined
                    color="blue accent-4"
                    text
                    target="_blank"
                    :href="val.link"
                    >{{ val.type }}</v-btn
                  >
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tableMixin from '../../mixin/shared/table-mixin';

import AddressForm from '@/components/Form/AddressForm';

import PersonForm from '@/components/Form/PersonForm';

import AcademicForm from '@/components/Form/AcademicForm';
import SupervisorForm from '@/components/Form/SupervisorForm';
import SectorForm from '@/components/Form/SectorForm';

export default {
  name: 'MoreDetails',
  mixins: [tableMixin],

  components: {
    AddressForm,
    PersonForm,
    AcademicForm,
    SupervisorForm,
    SectorForm,
  },
  data() {
    return {
      serviceSubject: '',
      showDialogAfterLoad: false,
    };
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: () => -1,
    },
    dialog: {
      type: Boolean,
      default: () => false,
    },
    serviceName: {
      type: String,
      default: () => '',
    },
  },

  async created() {
    this.serviceSubject = await import(`../../services/${this.serviceName}`);
    await this.loadFields();
  },

  methods: {
    async loadFields() {
      await this.findFields(this.serviceSubject.default.readById(this.id));
      this.showDialogAfterLoad = true;
    },
  },
};
</script>
