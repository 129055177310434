<template>
  <div>
    <simple-list
      :showPrintOption="true"
      printUrlPrefix="/termos/imprimir/"
      subject="academicos"
      serviceName="academic"
    />
  </div>
</template>

<script>
import SimpleList from '../../../components/List/SimpleList.vue';

export default {
  name: 'ListAcademic',
  components: { SimpleList },
};
</script>

<style scoped></style>
