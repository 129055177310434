<template>
  <base-form ref="baseForm" :fields="fields" form-name="address">
    <template v-slot="{ field }">
      <div v-if="field.ableInput">
        <v-text-field
          :label="field.label"
          v-model="addressLocal[field.prop]"
          v-mask="field.mask"
          :rules="field.rules"
          :maxLength="field.maxLength || false"
          :counter="isEditing || field.maxLength"
          :readonly="isViewOnlyMode"
          @input="uppercase(field.prop, $event)"
          outlined
        />
      </div>
      <div v-else>
        <v-text-field
          :label="field.label"
          v-model="addressLocal[field.prop]"
          :rules="field.rules"
          :maxLength="field.maxLength || false"
          :counter="field.maxLength || false"
          :disabled="!enableAddressInputs"
          :readonly="isViewOnlyMode"
          @input="uppercase(field.prop, $event)"
          outlined
        />
      </div>
    </template>
  </base-form>
</template>

<script>
import BaseForm from '@/components/Form/BaseForm';
import cep from 'cep-promise';
export default {
  name: 'AddressForm',
  components: { BaseForm },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    editForm: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    addressLocal: {
      get() {
        return this.address;
      },
      set(valor) {
        this.$emit('update:address', valor);
      },
    },
  },
  data() {
    return {
      isViewOnlyMode: false,
      enableAddressInputs: false,
      fields: [
        {
          ableInput: true,
          label: 'CEP',
          mask: 'XXXXX-XXX',
          prop: 'cep',
          rules: [this.$rules.required, this.$rules.validCep],
          cols: '6',
          maxLength: 9,
        },
        {
          label: 'Rua',
          prop: 'street',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 80,
        },
        {
          label: 'Cidade',
          prop: 'city',
          rules: [this.$rules.required],
          cols: '12',
          maxLength: 80,
        },
        {
          label: 'Bairro',
          prop: 'neighborhood',
          rules: [this.$rules.required],
          cols: '10',
          maxLength: 80,
        },
        {
          label: 'Estado',
          prop: 'state',
          rules: [this.$rules.required],
          cols: '2',
          maxLength: 40,
        },
      ],
    };
  },
  watch: {
    'address.cep'(value) {
      if (value.replace(/\D/g, '').length === 8) {
        this.searchAddressByCEP(value.replace(/\D/g, ''));
      }
    },
  },
  created() {
    if (this.address.id) {
      this.isViewOnlyMode = !this.editForm;
    }
  },
  methods: {
    requestBaseFormValidation() {
      return this.$refs.baseForm.validateBaseForm();
    },
    async searchAddressByCEP(value) {
      try {
        const { city, state, neighborhood, street } = await cep(value);
        this.addressLocal = {
          ...this.address,
          city: city.toUpperCase(),
          state: state.toUpperCase(),
          neighborhood: neighborhood.toUpperCase(),
          street: street.toUpperCase(),
        };
      } catch (e) {
        this.showError(
          'Algo deu errado ao buscar informações de endereço, por favor insira manuamente.',
        );
      } finally {
        this.enableAddressInputs = true;
      }
    },
    uppercase(prop, e) {
      this.addressLocal[prop] = e.toUpperCase();
    },
  },
};
</script>

<style scoped></style>
