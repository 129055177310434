<template>
  <base-form ref="baseForm" :fields="fields" form-name="internship">
    <template v-slot="{ field }">
      <div v-if="!field.select">
        <div v-if="field.mask">
          <v-text-field
            :label="field.label"
            v-model="internshipLocal[field.prop]"
            v-mask="field.mask"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            outlined
          />
        </div>
        <div v-else>
          <v-text-field
            :label="field.label"
            v-model="internshipLocal[field.prop]"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            outlined
          />
        </div>
      </div>

      <div v-else>
        <v-select
          :items="field.items"
          return-object
          item-value="id"
          item-text="title"
          v-model="internshipLocal[field.prop]"
          :label="field.label"
          :maxLength="field.maxLength || false"
          :counter="field.maxLength || false"
          :rules="field.rules"
          outlined
        ></v-select>
      </div>
    </template>
  </base-form>
</template>

<script>
import sectorService from '../../services/sector';
import supervisorService from '../../services/supervisor';
import internshipMixin from '../../mixin/internship/internship-mixin';
import BaseForm from '@/components/Form/BaseForm';
import dayjs from 'dayjs';

export default {
  name: 'InternshipForm',
  components: { BaseForm },
  mixins: [internshipMixin],

  props: {
    internship: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    internshipLocal: {
      get() {
        return this.internship;
      },
      set(valor) {
        this.$emit('update:internship', valor);
      },
    },
  },

  data() {
    return {
      isViewOnlyMode: false,
      fields: [],
    };
  },

  async created() {
    await this.loadSector();
    await this.loadSupervisor();
    if (this.internshipLocal.startDate || this.internshipLocal.endDate) {
      this.internshipLocal.startDate = dayjs(
        this.internshipLocal.startDate,
      ).format('DD/MM/YYYY');
      this.internshipLocal.endDate = dayjs(this.internshipLocal.endDate).format(
        'DD/MM/YYYY',
      );
    }

    await this.loadFields();
    if (this.internship.id) {
      this.isViewOnlyMode = false;
    }
  },
  methods: {
    requestBaseFormValidation() {
      return this.$refs.baseForm.validateBaseForm();
    },
    async loadSector() {
      await this.findSector(sectorService.read);
    },
    async loadSupervisor() {
      await this.findSupervisor(supervisorService.read);
    },
    loadFields() {
      this.fields = [
        {
          label: 'Selecionar Setor',
          prop: 'sector',
          items: this.sectorOptions,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '6',
        },
        {
          label: 'Selecionar Preceptor',
          prop: 'supervisor',
          items: this.supervisorOptions,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '6',
        },
        {
          label: 'Carga Horária',
          prop: 'workingHours',
          mask: '###',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 3,
        },
        {
          label: 'Hora de Início',
          prop: 'startHours',
          mask: '##:##',
          rules: [this.$rules.required],
          cols: '3',
          maxLength: 5,
        },
        {
          label: 'Hora de Encerramento',
          prop: 'endHours',
          mask: '##:##',
          rules: [this.$rules.required],
          cols: '3',
          maxLength: 5,
        },
        {
          label: 'Turma',
          prop: 'class',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 100,
        },
        {
          label: 'Data de Início',
          prop: 'startDate',
          mask: '##/##/####',
          rules: [this.$rules.required],
          cols: '3',
          maxLength: 10,
        },
        {
          label: 'Data de Encerramento',
          prop: 'endDate',
          mask: '##/##/####',
          rules: [this.$rules.required],
          cols: '3',
          maxLength: 10,
        },
        {
          label: 'Curso',
          prop: 'course',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 100,
        },
        {
          label: 'Selecionar Semestre',
          prop: 'semester',
          items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '6',
        },
      ];
    },
  },
};
</script>

<style scoped></style>
