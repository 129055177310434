const courseFieldEnum = [
  'Medicina',
  'Enfermagem',
  'Fisioterapia',
  'Nutrição',
  'Odontologia',
  'Psicologia',
  'Serviço Social',
  'Farmácia',
  'Biologia',
  'Biomedicina',
];

export default courseFieldEnum;
