<template>
  <div>
    <strong>Dados pessoais</strong>
    <person-form ref="personForm" :person.sync="person" />
    <strong>Endereco</strong>
    <address-form ref="addressForm" :address.sync="address" />
    <strong>Dados Acadêmicos</strong>
    <academic-form ref="academicForm" :academic.sync="academic" />

    <v-form ref="fileInputForm">
      <v-row class="pa-5">
        <v-col v-for="field in fields" :key="field.label" cols="3">
          <v-file-input
            :label="field.label"
            v-model="attachmentsFiles[field.field]"
            show-size
            truncate-length="31"
            :rules="field.rules"
            outlined
          ></v-file-input>
        </v-col>

        <v-col class="d-flex justify-center" cols="12">
          <v-btn
            x-large
            :loading="loading"
            color="secondary"
            class="ma-2 white--text"
            @click="$router.go(-1)"
          >
            Cancelar
          </v-btn>
          <v-btn
            x-large
            :loading="loading"
            color="green"
            class="ma-2 white--text"
            @click="submitFormAcademic"
          >
            Salvar
            <v-icon right dark> mdi-check-bold </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import PersonForm from '@/components/Form/PersonForm';
import AddressForm from '@/components/Form/AddressForm';
import AcademicForm from '@/components/Form/AcademicForm';
import academicService from '@/services/academic';
import fileUpload from '@/services/fileUpload';
import mask from '@/utils/mask';
import date from '@/utils/date';
import institutionFieldEnum from '@/enums/institutionFieldEnum';

export default {
  name: 'FormAcademic',
  components: { AddressForm, PersonForm, AcademicForm },

  data() {
    return {
      serviceSubject: '',
      fields: [
        {
          label: 'CPF e RG',
          field: 'fileRgCpf',
          rules: [this.$rules.requiredFile],
        },
        {
          label: 'Foto 3x4',
          field: 'filePicture3x4',
          rules: [this.$rules.requiredFile],
        },
        {
          label: 'Certificado de Matrícula',
          field: 'fileCertificate',
          rules: [this.$rules.requiredFile],
        },
        {
          label: 'Comprovante de Vacina',
          field: 'fileVaccination',
          rules: [this.$rules.requiredFile],
        },
      ],
      props: {
        subject: {
          type: String,
          default: () => '',
        },
      },
      loading: false,
      person: {
        name: '',
        sexo: '',
        bornDate: '',
        civilState: '',
        homePhone: '',
        mobilePhone: '',
        email: '',
        cpf: '',
        rg: '',
      },
      attachmentsFiles: {
        fileRgCpf: null,
        filePicture3x4: null,
        fileCertificate: null,
        fileVaccination: null,
      },
      address: {
        street: '',
        cep: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      academic: {
        instituition: institutionFieldEnum,
        course: '',
        ar: '',
        semester: '',
        internshipPeriod: '',
        medicalInsurance: '',
        allergies: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
      },
    };
  },

  methods: {
    async submitFormAcademic() {
      const validPersonForm = this.$refs.personForm.requestBaseFormValidation();
      const validAddressForm =this.$refs.addressForm.requestBaseFormValidation();
      const validAcademicForm =this.$refs.academicForm.requestBaseFormValidation();
      const validFileInputForm = this.$refs.fileInputForm.validate();

      if (
        !validPersonForm ||
        !validAddressForm ||
        !validAcademicForm ||
        !validFileInputForm
      ) {
        this.errorToast('Verifique os campos obrigatórios');
        return;
      }

      try {
        this.loading = true;
        // todo: usar promisse.all para paralelisar as requisicoes
        const { data: linkRgCpf } = await this.checkAndUploadImage('fileRgCpf');

        const { data: linkPicture3x4 } = await this.checkAndUploadImage(
          'filePicture3x4',
        );
        const { data: linkCertificate } = await this.checkAndUploadImage(
          'fileCertificate',
        );
        const { data: linkVaccination } = await this.checkAndUploadImage(
          'fileVaccination',
        );

        const attachments = {
          linkRgCpf,
          linkPicture3x4,
          linkCertificate,
          linkVaccination,
        };

        const person = { ...this.person };
        const address = { ...this.address };
        const academic = { ...this.academic };

        academic.emergencyContactPhone = mask.unmaskOnlyDigitString(
          academic.emergencyContactPhone,
        );

        person.cpf = mask.unmaskOnlyDigitString(person.cpf);
        person.homePhone = mask.unmaskOnlyDigitString(person.homePhone);
        person.mobilePhone = mask.unmaskOnlyDigitString(person.mobilePhone);
        person.bornDate = date.formatDateBrToUs(person.bornDate);
        address.cep = mask.unmaskOnlyDigitString(address.cep);

        await academicService.create({
          person,
          academic,
          address,
          attachments,
        });
        this.successToast('Salvo com Sucesso!');
        this.loading = false;
        window.history.back();
      } catch (error) {
        this.errorToast('Erro ao Salvar!');
        console.log(error);
      }
    },
    async checkAndUploadImage(fieldName) {
      if (this.attachmentsFiles[fieldName]) {
        try {
          const formData = new FormData();
          formData.append('files', this.attachmentsFiles[fieldName]);
          const response = await fileUpload.upload(formData);
          return response;
        } catch (e) {
          this.$handleHttpError(e);
        }
      }
    },
  },
};
</script>

<style scoped></style>
