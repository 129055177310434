<template>
  <div class="d-flex justify-center align-center">
    <v-card elevation="18" class="pa-5" width="500">
      <h4 class="ml-3">Faça login com e-mail e senha:</h4>
      <v-form
        class="d-flex flex-column justify-center align-center"
        ref="form"
        @keyup.native.enter="submit"
      >
        <v-text-field
          v-model="username"
          label="E-mail"
          type="email"
          :rules="[$rules.required]"
        />
        <v-text-field
          v-model="password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[$rules.required]"
          :type="show ? 'text' : 'password'"
          name="input-10-2"
          label="Senha"
          @click:append="show = !show"
        ></v-text-field>
      </v-form>
      <div class="d-flex flex-column justify-center align-center">
        <v-card-actions class="flex-column">
          <v-btn
            color="#2F3783"
            class="white--text"
            @keyup.native.enter="submit"
            @click="submit"
          >
            Enviar
          </v-btn>
        </v-card-actions>
        <v-btn color="#c9c9c9" :to="{ name: 'auth.forgotPassword' }">
          Esqueci minha senha
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import LoginService from '../../services/auth';
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      show: false,
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      try {
        const { data } = await LoginService.login({
          username: this.username,
          password: this.password,
        });
        this.login(data.token);
      } catch (error) {
        if (!error.response) {
          this.errorToast('Erro ao efetuar o Login');
          return;
        }
        if (error.response.data.error === 'Unauthorized') {
          this.errorToast(error.response.data.message);
        } else {
          this.errorToast('Erro ao efetuar o Login');
        }
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  width: 95%;
}
.v-btn:nth-child(1) {
  width: 450px;
}
.v-btn:nth-child(2) {
  width: 98%;
}
</style>
