import api from './api';

const url = 'upload/upload-file';

const upload = files =>
  api.post(url, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export default { upload };
