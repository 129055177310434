<template>
  <div>
    <simple-list
      :showDelete="true"
      :showDetails="false"
      subject="usuarios"
      serviceName="users"
      :propsHeaders="headers"
    />
  </div>
</template>

<script>
import SimpleList from '../../../components/List/SimpleList.vue';

export default {
  name: 'ListUsers',
  components: { SimpleList },

  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Nome', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Ações', value: 'actions' },
    ],
  }),
};
</script>

<style scoped></style>
