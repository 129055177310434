<template>
  <base-form ref="baseForm" :fields="fields" form-name="academic">
    <template v-slot="{ field }">
      <div v-if="!field.select">
        <div v-if="field.mask">
          <v-text-field
            :label="field.label"
            v-model="academicLocal[field.prop]"
            v-mask="field.mask"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            outlined
          />
        </div>
        <div v-else>
          <v-text-field
            :label="field.label"
            v-model="academicLocal[field.prop]"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            @input="uppercase(field.prop, $event)"
            outlined
          />
        </div>
      </div>
      <div v-else>
        <v-select
          :items="field.item"
          v-model="academicLocal[field.prop]"
          :label="field.label"
          :maxLength="field.maxLength || false"
          :counter="field.maxLength || false"
          :rules="field.rules"
          :readonly="isViewOnlyMode"
          outlined
        ></v-select>
      </div>
    </template>
  </base-form>
</template>

<script>
import BaseForm from '@/components/Form/BaseForm';
import internshipFieldEnum from '@/enums/internshipFieldEnum';
import institutionFieldEnum from '@/enums/institutionFieldEnum';
import semesterFieldEnum from '@/enums/semesterFieldEnum';
import internshipPeriodFieldEnum from '@/enums/internshipPeriodFieldEnum';
import courseFieldEnum from '@/enums/courseFieldEnum';
export default {
  name: 'AcademicForm',
  components: { BaseForm },
  props: {
    academic: {
      type: Object,
      default: () => {},
    },
    editForm: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    academicLocal: {
      get() {
        return this.academic;
      },
      set(valor) {
        this.$emit('update:academic', valor);
      },
    },
  },
  data() {
    return {
      isViewOnlyMode: false,
      fields: [
        {
          label: 'Instituição de Ensino Superior',
          prop: 'instituition',
          item: institutionFieldEnum,
          rules: [this.$rules.required],
          select: true,
          cols: '12',
          maxLength: 150,
        },
        {
          label: 'Curso',
          prop: 'course',
          item: courseFieldEnum,
          rules: [this.$rules.required],
          select: true,
          cols: '6',
          maxLength: 150,
        },
        {
          label: 'Área do Estágio',
          prop: 'internshipField',
          item: internshipFieldEnum,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '6',
        },
        {
          label: 'AR do Acadêmico',
          prop: 'ar',
          rules: [this.$rules.required],
          cols: '4',
          maxLength: 20,
        },
        {
          label: 'Semestre',
          prop: 'semester',
          item: semesterFieldEnum,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '4',
        },
        {
          label: 'Período',
          prop: 'internshipPeriod',
          item: internshipPeriodFieldEnum,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '4',
        },
        {
          label: 'Convênio Médico (Plano de Saúde)',
          prop: 'medicalInsurance',
          cols: '6',
          maxLength: 30,
        },
        {
          label: 'Alergias',
          prop: 'allergies',
          cols: '6',
          maxLength: 300,
        },
        {
          label: 'Nome do Contato de Emergência',
          prop: 'emergencyContactName',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 150,
        },
        {
          label: 'Telefone do Contato de Emergência',
          prop: 'emergencyContactPhone',
          mask: '(##) #####-####',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 15,
        },
      ],
    };
  },
  created() {
    if (this.academic.id) {
      this.isViewOnlyMode = !this.editForm;
    }
  },
  methods: {
    requestBaseFormValidation() {
      return this.$refs.baseForm.validateBaseForm();
    },
    uppercase(prop, e) {
      this.academicLocal[prop] = e.toUpperCase();
    },
  },
};
</script>

<style scoped></style>
