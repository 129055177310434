<template>
  <div>
    <v-app-bar
      fixed
      class="d-flex justify-space-around align-center header"
      elevation="0"
      color="white"
      dense
    >
      <v-card
        elevation="0"
        class="d-flex justify-space-between align-center card"
      >
        <div class="items">
          <img id="cassems" src="../assets/logoCassems.png" alt="" />
        </div>
        <div class="items">
          <p>TERMO DE COMPROMISSO - ESTÁGIO</p>
        </div>
        <div class="items list">
          <ul>
            <li><img id="nep" src="../assets/logo.png" alt="" /></li>
          </ul>
        </div>
      </v-card>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <commitment-term serviceName="student" />
      </v-container>
    </v-main>
    <v-app-bar elevation="0" color="white" bottom fixed dense>
      <img id="nep" src="../assets/logo.png" alt="" />
    </v-app-bar>
  </div>
</template>

<script>
import CommitmentTerm from '../components/Documents/CommitmentTerm.vue';
export default {
  components: { CommitmentTerm },
  name: 'DocumentLayout',
  data() {},
  serviceName: {
    type: String,
    default: () => '',
  },
};
</script>

<style scoped>
#cassems {
  width: 120px;
}
#nep {
  width: 70px;
}
li {
  list-style: none;
}
.card {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.items {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px;
}
p {
  margin: 0;
}
.header {
  position: fixed;
}
</style>
