<template>
  <div>
    <h2 class="text-uppercase font-weight-light mb-5">Usuários</h2>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'User',
};
</script>

<style scoped></style>