<template>
  <div>
    <internship-simple-list />
  </div>
</template>

<script>
import InternshipSimpleList from '../../../components/Internship/InternshipSimpleList.vue';

export default {
  name: 'ListInternship',
  components: { InternshipSimpleList },
};
</script>

<style scoped></style>
