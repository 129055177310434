import AuthLayout from '@/layouts/AuthLayout';
import Login from '@/views/auth/Login';
import ForgotPassword from '@/views/auth/forgotPassword';
import ResetPassword from '@/views/auth/ResetPassword';
import ConfirmEmail from '@/views/app/Users/ConfirmEmail';

export const authRoutes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: Login,
        meta: { requireLogin: false },
      },
      {
        path: 'esqueci-senha',
        name: 'auth.forgotPassword',
        component: ForgotPassword,
        meta: { requireLogin: false },
      },
      {
        path: 'trocar-senha/:token',
        name: 'resetpassword',
        component: ResetPassword,
        meta: { requireLogin: false },
      },
    ],
  },
  {
    path: '/users',
    component: AuthLayout,
    children: [
      {
        path: 'confirmar-email/:token',
        name: 'confirmar-email',
        component: ConfirmEmail,
        meta: { requireLogin: false },
      },
    ],
  },
];
