import api from '@/services/api';

const url = 'sector';

const create = sector => api.post(`${url}/create-sector`, sector);

const read = () => api.get(`${url}/read-sector`);

const readById = id => api.get(`${url}/read-sector/${id}`);

const inactive =  inactive => api.put(`${url}/inactve-sector`, inactive);

const update = form => api.put(`${url}/update-sector`, form)

export default { create, read, readById, inactive, update };
