import axios from 'axios';
import getEnv from '../config/env';

const api = axios.create({
  baseURL:
    getEnv('VUE_APP_API_URL') ||
    'http://nep-api.frontline-dev.in.cassems.com.br',
  timeout: 30000,
});

export default api;

api.interceptors.request.use(config => {
  const token = window.localStorage.getItem('@nep/token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
