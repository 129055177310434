import api from '@/services/api';

const url = 'supervisor';

const create = supervisor => api.post(`${url}/create-supervisor`, supervisor);

const read = () => api.get(`${url}/read-supervisor`);

const readById = id => api.get(`${url}/read-supervisor/${id}`);

const inactive =  inactive => api.put(`${url}/inactve-supervisor`, inactive);

const update = supervisor => api.put(`${url}/update-supervisor`, supervisor)

export default { create, read, readById, inactive, update };
