<template>
  <div class="d-flex justify-center align-center">
    <v-card elevation="18" class="pa-5" width="500">
      <h4>Coloque seu e-mail para recuperar a senha:</h4>
      <v-form ref="loginForm" @keyup.native.enter="submit">
        <v-text-field
          v-model="email"
          label="E-mail"
          :rules="[$rules.required, $rules.email]"
        />
      </v-form>
      <div class="d-flex flex-column justify-center align-center">
        <v-card-actions class="flex-column">
          <v-btn
            width="450"
            color="#2F3783"
            class="white--text"
            v-if="isSubmitting"
            disabled
          >
            <v-icon v-if="submitSuccess">mdi-check-bold</v-icon>
            <img
              v-else
              width="50px"
              src="../../assets/loading-circle-blue.svg"
            />
          </v-btn>
          <v-btn
            v-else
            width="450"
            color="#2F3783"
            class="white--text"
            @keyup.native.enter="submit"
            @click="submit"
          >
            Enviar
          </v-btn>
        </v-card-actions>
        <v-btn
          width="450"
          color="#c9c9c9"
          class="black--text"
          @keyup.native.enter="submit"
          :to="{ name: `auth.login` }"
        >
          Voltar
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import requestResetService from '../../services/users';
export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      isSubmitting: false,
      submitSuccess: false,
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.loginForm.validate()) return;
      this.isSubmitting = true;
      try {
        await requestResetService.requestResetPassword({
          email: this.email,
        });
        this.successToast('E-Mail Enviado com Sucesso!');
        this.submitSuccess = true;
      } catch (error) {
        if (!error.response) {
          this.errorToast('Erro ao Enviar E-Mail');
          return;
        }
        if (error.response.data.error === 'Not Found') {
          this.errorToast(error.response.data.message);
        } else {
          this.errorToast(
            'Erro ao Enviar E-Mail. Verifique e tente novamente.',
          );
        }
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped></style>
