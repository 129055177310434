<template>
  <div>
    <h2 class="text-uppercase font-weight-light mb-5">Planos de Estágio</h2>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Internship',
};
</script>
<style scoped></style>
