<template>
  <v-form :ref="formName">
    <v-row class="pa-5">
      <v-col v-for="(field, index) in fields" :key="index" :cols="field.cols">
        <slot :field="field" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    formName: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    validateBaseForm() {
      return this.$refs[this.formName].validate();
    },
  },
};
</script>
