<template>
  <div>
    <strong>Dados Pessoais</strong>
    <person-form ref="personForm" :person.sync="person" />
    <strong>Endereço</strong>
    <address-form ref="addressForm" :address.sync="address" />
    <strong>Dados do Estágio</strong>
    <supervisor-form ref="supervisorForm" :supervisor.sync="supervisor" />

    <v-form ref="fileInputForm">
      <v-row class="pa-5">
        <v-col v-for="field in fields" :key="field.label" cols="6">
          <v-file-input
            required
            :label="field.label"
            v-model="attachmentsFiles[field.field]"
            show-size
            truncate-length="31"
            :rules="field.rules"
            outlined
          ></v-file-input>
        </v-col>
        <v-col class="d-flex justify-center" cols="12">
          <v-btn
            x-large
            :loading="loading"
            color="secondary"
            class="ma-2 white--text"
            @click="$router.go(-1)"
          >
            Cancelar
          </v-btn>
          <v-btn
            x-large
            :loading="loading"
            color="green"
            class="ma-2 white--text"
            @click="submitFormSupervisor"
          >
            Salvar
            <v-icon right dark> mdi-check-bold </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import PersonForm from '@/components/Form/PersonForm';
import AddressForm from '@/components/Form/AddressForm';
import SupervisorForm from '@/components/Form/SupervisorForm';
import supervisorService from '@/services/supervisor';
import fileUpload from '@/services/fileUpload';
import institutionFieldEnum from '@/enums/institutionFieldEnum';
import mask from '@/utils/mask';
import date from '@/utils/date';

export default {
  name: 'FormSupervisor',
  components: { PersonForm, AddressForm, SupervisorForm },

  data() {
    return {
      fields: [
        {
          label: 'Carteira Profissional',
          field: 'linkProfessionalId',
          rules: [this.$rules.requiredFile],
        },
        {
          label: 'Foto 3x4',
          field: 'linkPicture3x4',
          rules: [this.$rules.requiredFile],
        },
      ],
      loading: false,
      person: {
        name: '',
        sexo: '',
        bornDate: '',
        civilState: '',
        homePhone: '',
        mobilePhone: '',
        email: '',
        cpf: '',
        rg: '',
      },
      attachmentsFiles: {
        linkProfessionalId: null,
        linkPicture3x4: null,
      },
      address: {
        street: '',
        cep: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      supervisor: {
        course: '',
        subject: '',
        semester: '',
        workingPeriod: '',
        institution: institutionFieldEnum,
        internshipField: '',
        workingHours: '',
        internshipSemester: '',
        startDate: '',
        endDate: '',
      },
    };
  },

  methods: {
    async submitFormSupervisor() {
      if (
        !this.$refs.personForm.requestBaseFormValidation() ||
        !this.$refs.addressForm.requestBaseFormValidation() ||
        !this.$refs.supervisorForm.requestBaseFormValidation() ||
        !this.$refs.fileInputForm.validate()
      ) {
        this.errorToast('Verifique os campos obrigatórios');
        return;
      }
      try {
        // todo: usar promisse.all para paralelisar as requisicoes
        this.loading = true;
        const { data: linkProfessionalId } = await this.checkAndUploadImage(
          'linkProfessionalId',
        );

        const { data: linkPicture3x4 } = await this.checkAndUploadImage(
          'linkPicture3x4',
        );

        const attachments = {
          linkProfessionalId,
          linkPicture3x4,
        };

        const person = { ...this.person };
        const address = { ...this.address };
        const supervisor = { ...this.supervisor };

        supervisor.startDate = date.formatDateBrToUs(supervisor.startDate);
        supervisor.endDate = date.formatDateBrToUs(supervisor.endDate);
        person.cpf = mask.unmaskOnlyDigitString(person.cpf);
        person.homePhone = mask.unmaskOnlyDigitString(person.homePhone);
        person.mobilePhone = mask.unmaskOnlyDigitString(person.mobilePhone);
        person.bornDate = date.formatDateBrToUs(person.bornDate);
        address.cep = mask.unmaskOnlyDigitString(address.cep);

        await supervisorService.create({
          person,
          address,
          supervisor,
          attachments,
        });
        this.successToast('Salvo com Sucesso!');
        this.loading = false;
        window.history.back();
      } catch (error) {
        this.errorToast('Erro ao Salvar!');
        console.log(error);
      }
    },
    async checkAndUploadImage(fieldName) {
      if (this.attachmentsFiles[fieldName]) {
        try {
          const formData = new FormData();
          formData.append('files', this.attachmentsFiles[fieldName]);
          const response = await fileUpload.upload(formData);
          return response;
        } catch (e) {
          this.$handleHttpError(e);
        }
      }
    },
  },
};
</script>

<style scoped></style>
