const toastMixin = {
  methods: {
    successToast(toastText) {
      this.$toast.success(toastText, {
        position: 'bottom-center',
        timeout: 2970,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false,
      });
    },

    async errorToast(toastText) {
      this.$toast.error(toastText, {
        position: 'bottom-center',
        timeout: 2970,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false,
      });
    },
  },
};

export default toastMixin;
