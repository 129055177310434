/* eslint-disable */
import api from './api';

const url = 'users';

const requestResetPassword = email =>
    api.post(`${url}/request-password-reset`, email);

const usersResetPassword = params =>
    api.post(`${url}/reset-password/${params.token}`, { password: params.password, });

const validateResetPassword = token =>
    api.get(`${url}/validate-reset-password-link/${token}`);

const create = userData =>
    api.post(`${url}/create-users`, userData);

const read = () => api.get(`${url}/read-users`);

const validateUser = token =>
    api.get(`${url}/confirm-email/${token}`);

const deleteOne = id => api.delete(`${url}/delete-users/${id}`);

export default {
    requestResetPassword,
    usersResetPassword,
    validateResetPassword,
    validateUser,
    create,
    deleteOne,
    read,
};
