<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Plano de Estágio</v-toolbar-title>
        </v-toolbar>

        <div class="pa-5">
          <h2 class="text-uppercase font-weight-light mb-5">
            Dados do Estágio
          </h2>
          <internship-form :internship.sync="fields" />
        </div>
        <div class="pa-5">
          <v-row class="ml-2 mr-2">
            <v-col cols="6">
              <addition-table-date :internshipId="id" />
            </v-col>
            <v-col cols="6">
              <addition-table-academic
                :internshipId="id"
                :internship.sync="fields"
              />
            </v-col>
          </v-row>

          <v-row class="pa-5">
            <v-col class="d-flex justify-center">
              <v-btn
                x-large
                :loading="false"
                color="secondary"
                class="ma-2 white--text"
                @click="$emit('close')"
              >
                Cancelar
              </v-btn>
              <v-btn
                x-large
                :loading="false"
                color="green"
                class="ma-2 white--text"
                @click="submitFormInternship"
              >
                Salvar
                <v-icon right dark> mdi-check-bold </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import internshipMixin from '../../mixin/internship/internship-mixin';
import internshipService from '../../services/internship';

import InternshipForm from '@/components/Form/InternshipForm';
import AdditionTableAcademic from './AdditionTableAcademic';
import AdditionTableDate from './AdditionTableDate';

export default {
  name: 'InternshipMoreDetails',
  mixins: [internshipMixin],

  components: { InternshipForm, AdditionTableAcademic, AdditionTableDate },

  props: {
    id: {
      type: Number,
      required: true,
      default: () => -1,
    },
    dialog: {
      type: Boolean,
      default: () => false,
    },
  },

  async created() {
    await this.loadFields();
  },

  methods: {
    async loadFields() {
      await this.findFields(internshipService.readById(this.id));
      
    },

    async submitFormInternship() {
      try {
        const internshipToUpdate = { ...this.fields };

        // todo: add service to handle prams before submit

        const myStartDateSplitted = internshipToUpdate.startDate.split('/');
        const myEndDateSplitted = internshipToUpdate.endDate.split('/');

        internshipToUpdate.workingHours = parseInt(
          internshipToUpdate.workingHours,
        );
        internshipToUpdate.startDate = dayjs(
          `${myStartDateSplitted[2]}/${myStartDateSplitted[1]}/${myStartDateSplitted[0]}`,
        );
        internshipToUpdate.endDate = dayjs(
          `${myEndDateSplitted[2]}/${myEndDateSplitted[1]}/${myEndDateSplitted[0]}`,
        );

        await internshipService.update(internshipToUpdate);
        this.successToast('Salvo com Sucesso');
        this.$emit('close');
      } catch (error) {
        this.errorToast('Erro ao Salvar');
      }
    },
  },
};
</script>
