import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.module';
import createPersistedState from 'vuex-persistedstate';
import { student } from './modules/student';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    student,
  },
  plugins: [createPersistedState()],
});
