<template>
  <div>
    <v-dialog :value="showDialogAfterLoad" fullscreen>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Lista de Detalhesaaa</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card>
        <div
          class="pa-5"
          v-if="serviceName === 'academic' || serviceName === 'supervisor'"
        >
          <h2 class="text-uppercase font-weight-light mb-5">Dados pessoais</h2>
          <person-form ref="personForm" :person.sync="fields.person" :isEditing="false" :editForm="true" />

          <h2 class="text-uppercase font-weight-light mb-5">Endereço</h2>
          <address-form ref="addressForm" :address="fields.person.address" :editForm="true"/>
        </div>

        <div class="pa-5" v-if="serviceName === 'academic'">
          <h2 class="text-uppercase font-weight-light mb-5">
            Dados Acadêmicos
          </h2>
          <academic-form ref="academicForm" :academic.sync="fields" :editForm="true" />
        </div>

        <div class="pa-5" v-if="serviceName === 'supervisor'">
          <h2 class="text-uppercase font-weight-light mb-5">
            Dados do Estágio
          </h2>
          <supervisor-form ref="supervisorForm" :supervisor.sync="fields" :editForm="true" />
        </div>

        <div class="pa-5" v-if="serviceName === 'sector'">
          <h2 class="text-uppercase font-weight-light mb-5">Dados do Setor</h2>
          <sector-form :sector.sync="fields" :editForm="true"/>
        </div>

        <!-- <v-simple-table
          class="pa-5"
          v-if="serviceName === 'academic'"
          dense
        >
          <template v-slot:default>
            <h2 class="text-uppercase font-weight-light mb-5">Arquivos</h2>
            <v-row no-gutters>
              <v-col v-for="attachField in attachFieldsAcademic" :key="attachField.label" cols="6">
              <v-file-input
                required
                :label="attachField.label"
                v-model="attachmentsFilesAcademic[attachField.field]"
                show-size
                truncate-length="31"
                outlined
              ></v-file-input>
            </v-col>
            </v-row>
          </template>
        </v-simple-table> -->

        <!-- <v-simple-table
          class="pa-5"
          v-if="serviceName === 'supervisor'"
          dense
        >
          <template v-slot:default>
            <h2 class="text-uppercase font-weight-light mb-5">Arquivos</h2>
            <v-row no-gutters>
              <v-col v-for="attachField in attachFieldsSupervisor" :key="attachField.label" cols="6">
              <v-file-input
                required
                :label="attachField.label"
                v-model="attachmentsFilesSurpevisor[attachField.field]"
                show-size
                truncate-length="31"
                outlined
              ></v-file-input>
            </v-col>
            </v-row>
          </template>
        </v-simple-table> -->
          <v-row class="pa-5">
            <v-col class="d-flex justify-center">
              <v-btn
                x-large
                :loading="false"
                color="secondary"
                class="ma-2 white--text"
                @click="$emit('close')"
              >
                Cancelar
              </v-btn>
              <v-btn
                x-large
                :loading="false"
                color="green"
                class="ma-2 white--text"
                @click="updateForm(serviceName)"
              >
                Salvar
                <v-icon right dark> mdi-check-bold </v-icon>
              </v-btn>
            </v-col>
          </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tableMixin from '../../mixin/shared/table-mixin';

import AddressForm from '@/components/Form/AddressForm';

import PersonForm from '@/components/Form/PersonForm';

import AcademicForm from '@/components/Form/AcademicForm';
import SupervisorForm from '@/components/Form/SupervisorForm';
import SectorForm from '@/components/Form/SectorForm';
import ServiceSupervisor from '../../services/supervisor'
import ServiceAcademic from '../../services/academic'
import mask from '@/utils/mask';
import date from '@/utils/date';

export default {
  name: 'MoreDetails',
  mixins: [tableMixin],

  components: {
    AddressForm,
    PersonForm,
    AcademicForm,
    SupervisorForm,
    SectorForm,
  },
  data() {
    return {
      serviceSubject: '',
      showDialogAfterLoad: false,
      attachmentsFilesSurpevisor: {
        linkProfessionalId: null,
        linkPicture3x4: null,
      },
      attachFieldsSupervisor: [
        {
          label: 'Carteira Profissional',
          field: 'linkProfessionalId',
        },
        {
          label: 'Foto 3x4',
          field: 'linkPicture3x4',
        },
      ],
      attachFieldsAcademic: [
        {
          label: 'CPF e RG',
          field: 'fileRgCpf',
        },
        {
          label: 'Foto 3x4',
          field: 'filePicture3x4',
        },
        {
          label: 'Certificado de Matrícula',
          field: 'fileCertificate',
        },
        {
          label: 'Comprovante de Vacina',
          field: 'fileVaccination',
        },
      ],
      attachmentsFilesAcademic: {
        fileRgCpf: null,
        filePicture3x4: null,
        fileCertificate: null,
        fileVaccination: null,
      },
    };
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: () => -1,
    },
    dialog: {
      type: Boolean,
      default: () => false,
    },
    serviceName: {
      type: String,
      default: () => '',
    },
  },

  async created() {
    this.serviceSubject = await import(`../../services/${this.serviceName}`);
    await this.loadFields();
  },

  methods: {
    async loadFields() {
      await this.findFields(this.serviceSubject.default.readById(this.id));
      this.showDialogAfterLoad = true;
    },

    async updateForm(serviceName){
      if(serviceName === 'sector'){
        try{
          const formUpdate =  { ...this.fields }
          await this.serviceSubject.default.update(formUpdate)
          this.successToast('Sucesso');
          this.$emit('close');
        }catch(error){
          this.errorToast('Erro ao Salvar');
        }
      }
      if(serviceName === 'supervisor'){
        const formAddress = this.fields.person.address
        var formPerson = this.fields.person
        delete formPerson.address
        var formSurpevisor = this.fields
        delete formSurpevisor.attch
        if (
          !this.$refs.personForm.requestBaseFormValidation() ||
          !this.$refs.addressForm.requestBaseFormValidation() ||
          !this.$refs.supervisorForm.requestBaseFormValidation()
        ) {
          this.errorToast('Verifique os campos obrigatórios');
          return;
        }
        try {
          // todo: usar promisse.all para paralelisar as requisicoes
          this.loading = true;
          const person = formPerson ;
          const address = formAddress;
          const supervisor = formSurpevisor ;
          

          supervisor.startDate = date.formatDateBrToUs(supervisor.startDate);
          supervisor.endDate = date.formatDateBrToUs(supervisor.endDate);
          person.cpf = mask.unmaskOnlyDigitString(person.cpf);
          person.homePhone = mask.unmaskOnlyDigitString(person.homePhone);
          person.mobilePhone = mask.unmaskOnlyDigitString(person.mobilePhone);
          person.bornDate = date.formatDateBrToUs(person.bornDate);
          address.cep = mask.unmaskOnlyDigitString(address.cep);

          await ServiceSupervisor.update({
            person,
            address,
            supervisor,
            // attachments,
          });
          this.successToast('Salvo com Sucesso!');
          this.loading = false;
          this.$emit('close');
        } catch (error) {
          this.errorToast('Erro ao Salvar!');
        }
      }
      if(serviceName === 'academic'){
        const formAddress = this.fields.person.address
        var formPerson = this.fields.person
        delete formPerson.address
        var formAcademic = this.fields
        delete formAcademic.attch
        delete formAcademic.person

        // delete FormAcademic.attch
        if (
          !this.$refs.personForm.requestBaseFormValidation() ||
          !this.$refs.addressForm.requestBaseFormValidation() ||
          !this.$refs.academicForm.requestBaseFormValidation()
        ) {
          this.errorToast('Verifique os campos obrigatórios');
          return;
        }
        try {
          // todo: usar promisse.all para paralelisar as requisicoes
          this.loading = true;
          // const attachments = {
          //   linkProfessionalId,
          //   linkPicture3x4,
          // };
          // const { data: linkProfessionalId } = await this.checkAndUploadImage(
          //   'linkProfessionalId',
          // );

          // const { data: linkPicture3x4 } = await this.checkAndUploadImage(
          //   'linkPicture3x4',
          // );

          

          const person = formPerson ;
          const address = formAddress;
          const academic = formAcademic ;
          

          academic.emergencyContactPhone = mask.unmaskOnlyDigitString(academic.emergencyContactPhone,);
          person.cpf = mask.unmaskOnlyDigitString(person.cpf);
          person.homePhone = mask.unmaskOnlyDigitString(person.homePhone);
          person.mobilePhone = mask.unmaskOnlyDigitString(person.mobilePhone);
          person.bornDate = date.formatDateBrToUs(person.bornDate);
          address.cep = mask.unmaskOnlyDigitString(address.cep);

          await ServiceAcademic.update({
            person,
            address,
            academic,
            // attachments,
          });
          this.successToast('Salvo com Sucesso!');
          this.loading = false;
          this.$emit('close');
        } catch (error) {
          this.errorToast('Erro ao Salvar!');
        }
      }
    },


    async checkAndUploadImage(fieldName) {
      if (this.attachmentsFiles[fieldName]) {
        try {
          const formData = new FormData();
          formData.append('files', this.attachmentsFiles[fieldName]);
          const response = await fileUpload.upload(formData);
          return response;
        } catch (e) {
          this.$handleHttpError(e);
        }
      }
    },
  },
};
</script>
