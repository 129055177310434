import AppLayout from '@/layouts/AppLayout';
import DocumentLayout from '@/layouts/DocumentLayout';
import Home from '@/views/app/Home';
import Academic from '@/views/app/Academic/Academic';
import ListAcademic from '@/views/app/Academic/ListAcademic';
import FormAcademic from '@/views/app/Academic/FormAcademic';

import Supervisor from '@/views/app/Supervisor/Supervisor';
import ListSupervisor from '@/views/app/Supervisor/ListSupervisor';
import FormSupervisor from '@/views/app/Supervisor/FormSupervisor';
import CommitmentTerm from '@/components/Documents/CommitmentTerm';

import Sector from '@/views/app/Sector/Sector';
import ListSector from '@/views/app/Sector/ListSector';
import FormSector from '@/views/app/Sector/FormSector';

import Internship from '@/views/app/Internship/Internship';
import ListInternship from '@/views/app/Internship/ListInternship';
import FormInternship from '@/views/app/Internship/FormInternship';

import Users from '@/views/app/Users/Users';
import ListUser from '@/views/app/Users/ListUser';
import FormUser from '@/views/app/Users/FormUser';

export const appRoutes = [
  {
    path: '/',
    name: 'home',
    meta: { requireLogin: true },
    component: AppLayout,
    children: [
      {
        path: 'inicio',
        name: 'home.inicio',
        component: Home,
        meta: {
          icon: 'mdi-view-dashboard-outline',
          title: 'Inicio',
          requireLogin: true,
        },
      },
      {
        path: 'academicos',
        component: Academic,
        meta: {
          icon: 'mdi-account',
          title: 'Acadêmicos',
          requireLogin: true,
        },
        children: [
          {
            path: '',
            name: 'home.academicos',
            component: ListAcademic,
            meta: { requireLogin: true },
          },
          {
            path: 'cadastro',
            name: 'academicos.cadastro',
            component: FormAcademic,
            meta: { requireLogin: true },
          },
        ],
      },
      {
        path: 'professores',
        component: Supervisor,
        meta: {
          icon: 'mdi-school',
          title: 'Professores',
          requireLogin: true,
        },
        children: [
          {
            path: '',
            name: 'home.professores',
            component: ListSupervisor,
            meta: { requireLogin: true },
          },
          {
            path: 'cadastro',
            name: 'professores.cadastro',
            component: FormSupervisor,
            meta: { requireLogin: true },
          },
        ],
      },
      {
        path: 'setores',
        component: Sector,
        meta: {
          icon: 'mdi-account-group',
          title: 'Setores',
        },
        children: [
          {
            path: '',
            name: 'home.setores',
            component: ListSector,
          },
          {
            path: 'cadastro',
            name: 'setores.cadastro',
            component: FormSector,
          },
        ],
      },
      {
        path: 'planos-de-estagio',
        component: Internship,
        meta: {
          icon: 'mdi-briefcase',
          title: 'Planos de Estágio',
        },
        children: [
          {
            path: '',
            name: 'home.planos-de-estagio',
            component: ListInternship,
          },
          {
            path: 'cadastro',
            name: 'planos-de-estagio.cadastro',
            component: FormInternship,
          },
        ],
      },
      {
        path: 'usuarios',
        component: Users,
        meta: {
          title: 'Usuários',
          icon: 'mdi-account-group',
        },
        children: [
          {
            path: '',
            name: 'home.usuarios',
            component: ListUser,
            meta: { requireLogin: true },
          },
          {
            path: 'cadastro',
            name: 'usuarios.cadastro',
            component: FormUser,
            meta: { requireLogin: true },
          },
        ],
      },
    ],
  },
  {
    path: '/termos',
    name: 'termos',
    component: DocumentLayout,
    children: [
      {
        path: 'imprimir/:id',
        name: 'termos.imprimir',
        component: CommitmentTerm,
      },
    ],
  },
];
