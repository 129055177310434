<template>
  <v-main class="mt-16 mb-16">
    <p>As partes a seguir qualificadas</p>
    <table
      :id="subjectId"
      :serviceName="serviceName"
      class="tg"
      style="undefined;table-layout: fixed; width: 758px"
    >
      <colgroup>
        <col style="width: 300px" />
        <col style="width: 300px" />
        <col style="width: 300px" />
      </colgroup>
      <thead>
        <tr>
          <th class="tg-ej00" colspan="3">Estudante/Estágio</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="tg-x5og" colspan="3">Nome: {{ fields.person.name }}</td>
        </tr>

        <tr>
          <td class="tg-x5og">
            Data de Nascimento: {{ fields.person.bornDate }}
          </td>
          <td class="tg-x5og">RG: {{ fields.person.rg }}</td>
          <td class="tg-x5og">CPF: {{ fields.person.cpf }}</td>
        </tr>
        <tr>
          <td class="tg-x5og" colspan="3">
            Endereço: {{ fields.person.address.street }} -
            {{ fields.person.address.neighborhood }} -
            {{ fields.person.address.city }}
          </td>
        </tr>
        <tr>
          <td class="tg-x5og">Telefone: {{ fields.person.mobilePhone }}</td>
          <td class="tg-x5og" colspan="2">Email: {{ fields.person.email }}</td>
        </tr>
        <tr>
          <td class="tg-x5og" colspan="3">
            Instituição de ensino: {{ fields.instituition }}
          </td>
        </tr>
        <tr>
          <td class="tg-x5og" colspan="2">Curso: {{ fields.course }}</td>
          <td class="tg-x5og">Semestre: {{ fields.semester }}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <div class="textContent">
      <p>
        Unidade Cedente: CAIXA DE ASSISTÊNCIA DOS SERVIDORES DO ESTADO DO MATO
        GROSSO DO SUL - CASSEMS nome fantasia HOSPITAL CASSEMS - UNIDADE CAMPO
        GRANDE, localizada na Avenida Mato Grosso, 5151 - Carandá Bosque - Campo
        Grande/MS, pessoa jurídica de direito provado, inscrita no CNPJ sob o n°
        04.311.093/0014-40.
      </p>
      <p>
        Instituição de Ensino: ANHANGUERA EDUCACIONAL LTDA., pessoa jurídica de
        direito privado, inscrita no CNPJ sob o n° 05.808.792/0001-49 e com sede
        na Cidade de Valinhos/SP e filial em Campo Grande/MS, inscrita no CNPJ
        sob o n° 05.808.792/0065-03, mantenedora da UNIVERSIDADE ANHANGUERA -
        UNIDERP, situada na Rua Ceará, 333 - Bairro Miguel Couto - Campo
        Grande/MS.
      </p>
      <p>
        Têm entre si, justo e acertado, firmarem o presente termo de compromisso
        para estágio, de acordo com a cláusulas e condições que seguem:
      </p>
      <p>
        CLÁUSULA 1ª - A unidade concedente e o estudante acima identificado
        resolvem celebrar o presente instrumento para estabelecer as condições
        de realização de atividade de estágio, com a supervisão da instituição
        de Ensino, a fim de proporcionar ao estagiário a complementação do
        ensino e aprendizagem em ambiente de trabalho, através de experiência
        prática em sua linha de formação, em situação real de trabalho, como
        aprimoramento técnico-profissional, cultural, científico, de
        relacionamento humano e de cidadania, conforme especificam os planos de
        atividades e relatórios anexos.
      </p>
      <p>
        CLÁUSULA 2ª - A oportunidade de estágio obrigatório, objeto deste TERMO
        DE COMPROMISSO DE ESTÁGIO, configura-se de acordo com as seguintes
        condições:
      </p>
      <p>
        § 1ª - As partes têm ciencia de que a carga horária não poderá
        ultrapassar: 4 (quatro) - 8 (oito) hoas diárias.
      </p>
      <p>
        § 2ª - O estudante e a unidade concedente da oportunidade de estágio
        declaram que as atividades que serão desenvolvidas são efetivamente as
        previstas neste instrumento e as descritas nos relatórios e planos de
        estágio.
      </p>
      <p>
        § 3ª - A duração do estágio na mesma parte concedente não poderá exceder
        4 (quatro) anos, exceto quando se tratar de estágiario portador de
        deficiência com frequência regular no curso que estiver efetivamente
        matriculado.
      </p>
      <p>
        CLÁUSULA 3ª - São condutas e deveres do estudante em campo de estágio:
      </p>
      <ul>
        <li>
          I. Elaborar o plano de atividades de caordo com a unidade concedente e
          a instituição de Ensino, que será incorporado ao termo de compromisso
          por meio de aditivos à medida que seu desempenho for avaliado,
          progressivamente;
        </li>
        <li>
          II. Comunicar imediatamente e por escrito à instituição de Ensino
          sobre qualquer irregularidade quanto ao estágio.
        </li>
        <li>
          III. Cumprir as orientações, as normas e os regulamentos da unidade
          concedente e mater sigilo sobre as informações e dados que a tiver
          acesso em razão das atividade desempenhadas.
        </li>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <li>
          IV. Apresentar periodicamente à unidade concedente documents que
          comprovem sua frequência e matrícula;
        </li>
        <li>
          V. Não se ausentar do campo de práticas, durante horário de
          atividades, salvo quando autorizado pelo apoio da preceptoria in loco;
        </li>
        <li>
          VI. De acordo com NR 32, manter unhas curtas (rente aos dedos) e não
          usar aliança, anis, pulseiras, colares e quaisquer adornos que,
          porventura, possam trazer implicações à qualidade do cuidado prestado
          ao paciente;
        </li>
        <li>
          VII. A utilização de esmaltes será condicionada às normas da
          Instituição concedente;
        </li>
        <li>VIII. Os estudantes deverão estar com cabelos presos;</li>
        <li>IX. As estudantes deverão estar com cabelos presos;</li>
        <li>
          X. Evitar manifestações barulhentas em qualquer recinto da
          Instituição;
        </li>
        <li>
          XI. Fumar, consumir bebidas alcoólicas, usar drogas ilicitas, são atos
          proibidos
        </li>
        <li>
          XII. Recusar qualquer tipo de gratificação pelo cuidado prestado em
          campo de práticas;
        </li>
        <li>
          XIII. Não utilizar o celular na UTI, Centro Cirúrgico, nas Unidades de
          Internação ou no Pronto Atendimento. Os aparelhos deverão ser mantidos
          no modo silencioso. É proibido filmar, fotografar ou realizar
          gravações nessas dependências;
        </li>
        <li>
          XIV. Respeitar o sigilo e a confidencialidade dos atos, fatos e
          documentos de qualquer natureza de que venha ter conhecimento no
          decorrer do estagio, sujeitando-se a responder judicialmente pela
          intração que vier a praticar, na forma da lei;
        </li>
        <li>
          XV. Portar, obrigatoriamente, crachá de identificação da Instituição
          de Ensino;
        </li>
        <li>
          XVI. Usar roupas adequadas, respeitando o pudor, não sendo permitido:
          o uso de bermudas, camisetas regatas, calçados abertos, roupas
          decotadas ou entrada no hospital sem camisa. È permitido o uso de saia
          midi, saia abaixo do joelho e calça comprida.
        </li>
      </ul>
      <p>CLÁUSULA 4ª - São obrigações da unidade concedente:</p>
      <ul>
        <li>
          I. Orientar o estagiario quanto à realização de suas atividades, que
          devem ser compativeis com o curso e que dever ser descritas no termo
          de compromisso, nos relatórios e no plano de estágio;
        </li>
        <li>
          II. Ofertar instalacoes Que tenham condicoes de proporcionar
          profissional e cultural;
        </li>
        <li>
          III. Manter à disposicão da fiscalizacão documentos que comprovem a
          relacao;
        </li>
        <li>
          IV. Permitir que o professor onentador, designado pela Instituição de
          Ensino dossa etetivamente acompannar realização do estágio;
        </li>
        <li>
          V. Comunicar à Instituição de Ensino sobre qualquer irregularidade
          quanto ao estágio.
        </li>
      </ul>
      <p>CLAUSULA 5ª - São obrigações da Instituição de Ensino:</p>
      <ul>
        <li>
          I. Indicar as condições de adequação do estágio à proposta pedagógica
          do curso, à etapa e modalidade da formação escolar do estudante e ao
          horário e calendário escolar;
        </li>
        <li>
          II. Avaliar as instalações da parte concedente do estágio e sua
          adequação à formação cultural e profissional do educando;
        </li>
        <li>
          III. Indicar professor onentador, da area a ser desenvolvida no
          estagio, como responsavel pelo acompanhamento e avaliação das
          atividades do estagiário;
        </li>
        <li>
          IV. Exigir do educando a apresentação periódica, em prazo não superior
          a 6 (seis) meses, de relatório das atividades;
        </li>
        <li>
          V. Zelar Delo cumprimento do termo de compromisso, reonentando 0
          estagiano para outro local em caso de descumprimento de suas normas;
        </li>
        <li>
          VI. Encaminhar cada semestre letivo o calendario acadèmico
          especificando escalas, rodizios e a lista nominal dos acadêmicos e
          docentes aue desenvolverão as atividades acadèmicas no Hospital
          CASSEMS Unidade Campo Grande;
        </li>
        <li>
          VII. Elaborar normas complementares e instrumentos de avaliação dos
          estágios de seus alunos;
        </li>
        <li>
          VIII. Providenciar a entrega da cópia do seguro colletivo contra
          acidentes pessoais e acidentes eventuais a favor dos acadèmicos;
        </li>
        <li>
          IX. Comunicar à parte concedente do estágio as datas de realização de
          avaliações escolars ou acadêmicas.
        </li>
      </ul>
      <p>
        CLÁUSULA 6ª - Constituem motivos para interrupção automática da vigência
        do presente termo de compromisso:
      </p>
      <div>
        <ul>
          <li>
            I. A conclusão ou o abandono do curso e o trancamento da matricula;
          </li>
          <li>
            II. A transferência para outro curso ou para outra Instituição de
            Ensino;
          </li>
          <li>
            III. Quando o estágio completar dois anos, exceto quando se tratar
            de portador de deficiência;
          </li>
          <li>
            IV. O término da vigência, ou a rescisão antecipada, do convênio
            celebrado entre a unidade concedente e a Instituição de Ensino.
          </li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <p>
        Parágrafo único - Qualquer uma das partes, a qualquer momento, poderá
        encerrar o presente instrumento, desde que comunique às outras, no
        mínimo, com 05 (cinco) dias de antecedência, a fim de regularizar todas
        as pendências.
      </p>
      <p>CLÁUSULA 7ª - As partes concordam que:</p>
      <ul>
        <li>
          I. Qualquer alteração deste termo somente será válida se efetuada por
          escrito, através de aditivo assinado pelas partes, através de seus
          representantes legais;
        </li>
        <li>
          II. Nenhuma das partes será responsabilizada pelo não cumprimento de
          qualquer das disposições deste termo se o inadimplemento for
          decorrente de caso fortuito ou força maior;
        </li>
        <li>
          III. Fica vedado a qualquer das partes, sem expressa anuência da
          outra, transferir ou ceder, a qualquer título, os direitos e
          obrigações assumidos neste termo;
        </li>
        <li>
          IV. Fica certo e ajustado que nenhuma das partes tem poderes para
          representar ou obrigar a outra, a qualquer título ou sob qualquer
          pretexto;
        </li>
        <li>
          V. Não fica estabelecida por este instrumento qualquer
          responsabilidade solidária ou subsidiária, sendo que cada parte
          responderá exclusivamente por seus atos, na media de sua participação.
        </li>
      </ul>
      <p>
        CLÁUSULA 8ª - Para dirimir qualquer questão que se originar deste
        instrumento jurídico e que não possa ser resolvida amigavelmente, as
        partes elegem o foro de Campo Grande - MS.
      </p>
      <p>
        E assim, por estarem de inteiro e comum acordo com as condições e
        dizeres deste termo de compromisso, as partes assinam em 3 (três) vias,
        cabendo a primeira à unidade concedente, a segunda ao/à estagiário(a) e
        a terceira à Instituição de Ensino.
      </p>
    </div>
    <div class="sign">
      <div>
        <p>Unidade concedente do estágio:</p>
        <br />
        <hr />
      </div>
      <div>
        <p>Estudante / Estagiário:</p>
        <br />
        <hr />
      </div>
      <div>
        <p>Instituição de Ensino:</p>
        <br />
        <hr />
      </div>
    </div>
    <br />
    <p>
      Campo Grande, {{ currentDay }} de {{ currentmonth }} -
      {{ currentYear }}
    </p>
  </v-main>
</template>

<script>
import tableMixin from '../../mixin/shared/table-mixin';
import academicService from '@/services/academic';
import dayjs from 'dayjs';

const month = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];
const data = new Date();
export default {
  mixins: [tableMixin],
  name: 'CommitmentTerm',

  data() {
    return {
      currentYear: new Date().getFullYear(),
      currentDay: new Date().getDate(),
      currentmonth: month[data.getMonth()],
    };
  },
  serviceSubject: '',
  props: {
    id: {
      type: Number,
      required: true,
      default: () => -1,
    },
    serviceName: {
      type: String,
      default: () => '',
    },
  },
  async created() {
    await this.loadFields();
    await this.fields.person.bornDate;
    window.print();
    window.close();
  },
  methods: {
    async loadFields() {
      await this.findFields(academicService.readById(this.$route.params.id));
      this.fields.person.bornDate = dayjs(
        this.fields.person.bornDate,
        'YYYY-MM-DD',
      ).format('DD/MM/YYYY');
    },
  },
};
</script>

<style scoped>
@page {
  size: A4 !important;
  margin: 18mm 15mm 8mm 15mm !important;
}
.sign {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 4rem;
}
.tg {
  border-collapse: collapse;
  border-color: #93a1a1;
  border-spacing: 0;
}
.tg td {
  border-color: #93a1a1;
  border-style: solid;
  border-width: 1px;
  color: #002b36;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 15px 15px;
  word-break: normal;
}
.tg th {
  border-color: #93a1a1;
  border-style: solid;
  border-width: 1px;
  color: #fdf6e3;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 15px 15px;
  word-break: normal;
}
.tg .tg-x5og {
  border-color: #656565;
  color: #343434;
  text-align: left;
  vertical-align: top;
}
.tg .tg-ej00 {
  border-color: #656565;
  color: #202020;
  text-align: left;
  top: -1px;
  vertical-align: top;
  will-change: transform;
}
.textContent {
  text-align: justify;
  text-justify: justify;
}
</style>
