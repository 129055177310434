<template>
  <base-form ref="baseForm" :fields="fields" form-name="user">
    <template v-slot="{ field }">
      <div v-if="!field.select">
        <div v-if="field.mask">
          <v-text-field
            :label="field.label"
            v-model="userLocal[field.prop]"
            v-mask="field.mask"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            outlined
          />
        </div>
        <div v-else>
          <v-text-field
            :label="field.label"
            v-model="userLocal[field.prop]"
            :maxLength="field.maxLength || false"
            :counter="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            outlined
          />
        </div>
      </div>
      <div v-else>
        <v-select
          :items="field.item"
          v-model="userLocal[field.prop]"
          :label="field.label"
          :maxLength="field.maxLength || false"
          :counter="field.maxLength || false"
          :rules="field.rules"
          :readonly="isViewOnlyMode"
          outlined
        ></v-select>
      </div>
    </template>
  </base-form>
</template>

<script>
import BaseForm from '@/components/Form/BaseForm';
export default {
  name: 'AcademicForm',
  components: { BaseForm },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    userLocal: {
      get() {
        return this.user;
      },
      set(valor) {
        this.$emit('update:user', valor);
      },
    },
  },
  data() {
    return {
      isViewOnlyMode: false,
      fields: [
        {
          label: 'Nome',
          prop: 'name',
          rules: [this.$rules.required],
          cols: '12',
          maxLength: 150,
        },
        {
          label: 'Email',
          prop: 'email',
          rules: [this.$rules.required, this.$rules.email],
          cols: '12',
          maxLength: 150,
        },
        {
          label: 'Senha',
          prop: 'password',
          rules: [this.$rules.required, this.$rules.password],
          cols: '12',
          maxLength: 32,
        },
      ],
    };
  },
  methods: {
    requestBaseFormValidation() {
      return this.$refs.baseForm.validateBaseForm();
    },
  },
};
</script>

<style scoped></style>
