<template>
  <div>
    <h2 class="text-uppercase font-weight-light mb-5">Professores</h2>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Supervisor',
};
</script>

<style scoped></style>
