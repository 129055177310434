import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/misc/rules';

import Toast from 'vue-toastification';
import { toastConfig } from '@/plugins/toast';
import { formRules } from '@/misc/form-rules';
import 'vue-toastification/dist/index.css';
import '@/misc/filters';
import mixinToast from '@/mixin/shared/toast-mixin';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.mixin(mixinToast);

Vue.use(Toast, toastConfig);

Vue.use(VueGtag, {
  config: { id: "G-KV9WG0SKL6" }
}, router)

Vue.prototype.$rules = formRules;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
