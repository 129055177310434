import api from '@/services/api';

const url = 'internship';

const create = internship => api.post(`${url}/create-internship`, internship);

const read = () => api.get(`${url}/read-internship`);

const readById = id => api.get(`${url}/read-internship-by-id/${id}`);

const update = internship => api.put(`${url}/update-internship`, internship);

const downloadInternshipCSVReport = id =>
  api.get(`${url}/report-internship-academic/${id}`, {
    responseType: 'arraybuffer',
  });

export default { create, read, readById, update, downloadInternshipCSVReport };
