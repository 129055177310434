export const student = {
  namespaced: true,
  state: {
    studentsList: [],
    singleStudent: {},
  },
  mutations: {
    addToList(state, payload) {
      state.studentsList = [...state.studentsList, payload];
    },
    clearList(state) {
      state.studentsList = [];
    },
    add(state, payload) {
      state.singleStudent = payload;
    },
    clear(state) {
      state.singleStudent = {};
    },
  },
  actions: {},
  getters: {},
};
