<template>
  <v-row justify="center" align="center" no-gutters>
    <v-img
      :src="require('../../assets/logo.png')"
      height="187px"
      width="269px"
      contain
    />
  </v-row>
</template>

<script>
export default {
  name: 'Home',
};
</script>
