import Vue from 'vue';
import VueRouter from 'vue-router';
import { authRoutes } from '@/router/routes/auth';
import { appRoutes } from '@/router/routes/app';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...authRoutes, ...appRoutes],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireLogin && !store.getters['auth/isLogged']) {
    store.dispatch('auth/logout');
    return;
  }

  next();
});

export default router;
