const tableMixin = {
  data() {
    return {
      visible: false,
      dialogDelete: false,
      subjectId: '',
      items: [],
      fields: [],
    };
  },

  methods: {
    async findItems(findMethod) {
      try {
        const { data } = await findMethod();
        this.items = data;
      } catch (e) {
        console.error(e);
      }
    },

    async findFields(findMethod) {
      try {
        const { data } = await findMethod;
        this.fields = data;
      } catch (e) {
        console.error(e);
      }
    },

    viewItem(id) {
      this.subjectId = id;
      this.visible = !this.visible;
    },
    editItem(id) {
      this.subjectId = id;
      this.visibleEdit = !this.visibleEdit;
    },
    close() {
      this.$emit('close');
    },
    prepareToDelete(item) {
      if (item) {
        this.dialogDelete = true;
        this.itemIndex = this.items.findIndex(i => i.id === item.id);
      }
    },
    async deleteItem(deleteMethod, item) {
      try {
        let itemToDelete;
        if (this.itemIndex < 0) {
          this.prepareToDelete(item);
        } else {
          itemToDelete = this.items[this.itemIndex]?.id;
        }
        if (deleteMethod) {
          await deleteMethod(itemToDelete);
        }
        this.items.splice(this.itemIndex, 1);
        this.dialogDelete = false;
        this.successToast('Deletado com Sucesso!');
      } catch (error) {
        this.dialogDelete = false;
        this.errorToast('Erro ao Deletar!');
        console.log(error);
      }
    },
  },
};

export default tableMixin;
