<template>
  <div>
    <v-container fluid>
      <v-data-table :headers="propsHeaders" :items="items" :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="secondary" :to="{ name: `${subject}.cadastro` }">
              Adicionar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="showDetails"
                  small
                  class="mr-2"
                  @click.prevent="viewItem(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="showPrintOption"
                  small
                  class="mr-2"
                  @click.prevent="printDocument(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-printer
                </v-icon>
              </template>
              <span>Imprimir</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="showDelete"
                  class="mr-2"
                  @click.prevent="prepareToDelete(item)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Excluir</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  @click.prevent="editItem(item.id)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-account-edit</v-icon
                >
              </template>
              <span>Editar</span>
            </v-tooltip>

            <div v-if="serviceName == 'sector' && item.inactive == false">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click.prevent="inactiveActive(item.id, true)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-close</v-icon
                  >
                </template>
                <span>Inativar</span>
              </v-tooltip>
            </div>
            <div v-else-if="serviceName == 'sector' && item.inactive == true">
              <v-tooltip top>
                <template
                  v-slot:activator="{ on, attrs }"
                  v-if="serviceName == 'sector' && item.inactive == true"
                >
                  <v-icon
                    class="mr-2"
                    @click.prevent="inactiveActive(item.id, false)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-check</v-icon
                  >
                </template>
                <span>Ativar</span>
              </v-tooltip>
            </div>

            <div
              v-if="
                serviceName == 'supervisor' && item.person.inactive == false
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click.prevent="inactiveActive(item.person.id, true)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-check</v-icon
                  >
                </template>
                <span>Ativar</span>
              </v-tooltip>
            </div>
            <div
              v-else-if="
                serviceName == 'supervisor' && item.person.inactive == true
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click.prevent="inactiveActive(item.person.id, false)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-close</v-icon
                  >
                </template>
                <span>Inativar</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-container>

    <div class="pa-5">
      <more-details
        v-if="visible"
        @close="visible = !visible"
        :dialog="visible"
        :id="subjectId"
        :serviceName="serviceName"
      />
      <delete-confirmation
        :visible="dialogDelete"
        @cancel="dialogDelete = false"
        @confirm="deleteItem(ServiceUser.deleteOne)"
      />
      <edit-form
        v-if="visibleEdit"
        :visible="visibleEdit"
        @close="visibleEdit = !visibleEdit"
        :dialog="visibleEdit"
        :id="subjectId"
        :serviceName="serviceName"
      />
    </div>
  </div>
</template>

<script>
import tableMixin from '../../mixin/shared/table-mixin';
import MoreDetails from '../List/MoreDetails';
import EditForm from '../List/EditForm';
import DeleteConfirmation from '../shared/DeleteConfirmation';
import ServiceUser from '@/services/users';

export default {
  name: 'SimpleList',
  mixins: [tableMixin],
  components: { MoreDetails, EditForm, DeleteConfirmation },

  data: () => ({
    show: false,
    search: '',
    serviceSubject: '',
    ServiceUser: ServiceUser,
    visibleEdit: false,
  }),
  async created() {
    this.serviceSubject = await import(`../../services/${this.serviceName}`);
    await this.loadItemList();
  },

  props: {
    subject: {
      type: String,
      default: () => '',
    },
    serviceName: {
      type: String,
      default: () => '',
    },
    showDetails: {
      type: Boolean,
      default: () => true,
    },
    showDelete: {
      type: Boolean,
      default: () => false,
    },
    showEdit: {
      type: Boolean,
      default: () => true,
    },
    showInactive: {
      type: Boolean,
      default: () => true,
    },
    showActive: {
      type: Boolean,
      default: () => false,
    },
    propsHeaders: {
      type: Array,
      default: () => [
        { text: 'Nome', value: 'person.name' },
        { text: 'CPF', value: 'person.cpf' },
        { text: 'Contato', value: 'person.mobilePhone' },
        { text: 'Curso', value: 'course' },
        { text: 'Ações', value: 'actions' },
      ],
    },
    showPrintOption: {
      type: Boolean,
      default: () => false,
    },
    printUrlPrefix: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    async loadItemList() {
      this.dataList = await this.findItems(this.serviceSubject.default.read);
    },
    printDocument(academicId) {
      window.open(`${this.printUrlPrefix}${academicId}`);
    },
    async inactiveActive(id, inactive) {
      try {
        const object = { id, inactive };
        await this.serviceSubject.default.inactive(object);
        this.successToast('Sucesso');
        this.$emit('close');
        this.loadItemList();
      } catch (error) {
        this.errorToast('Erro ao Salvar');
      }
    },
  },
};
</script>

<style scoped></style>
