const internshipFieldsEnum = [
  'Anestesiologia',
  'Clínica Cirúrgica',
  'Clínica Médica Adulta',
  'Clínica Médica Pediátrica',
  'PAM Adulto',
  'PAM Pediátrico',
  'UTI Cardiologia',
  'UTI Geral',
  'UTI Pediátrica',
  'Hemodinâmica',
  'Oncologia',
];

export default internshipFieldsEnum;
