<template>
  <div>
    <strong>Cadastro</strong>
    <user-form ref="UserForm" :user.sync="user" />

    <v-row class="pa-5">
      <v-col class="d-flex justify-center" cols="12">
        <v-btn
          x-large
          :loading="false"
          color="secondary"
          class="ma-2 white--text"
          @click="$router.go(-1)"
        >
          Cancelar
        </v-btn>
        <v-btn
          :disabled="isLoading"
          x-large
          color="green"
          class="ma-2 white--text"
          @click="submitFormUser"
        >
          Salvar
          <v-icon v-if="isLoading" right dark> mdi-loading </v-icon>
          <v-icon v-else right dark> mdi-check-bold </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserForm from '@/components/Form/UserForm';
import UserService from '@/services/users.js';

export default {
  name: 'FormUser',
  components: { UserForm },

  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
      },
      isLoading: false,
    };
  },

  methods: {
    async submitFormUser() {
      if (!this.$refs.UserForm.requestBaseFormValidation()) {
        this.errorToast('Verifique os campos obrigatórios');
        return;
      }
      this.isLoading = true;
      try {
        const user = { ...this.user };
        await UserService.create(user);
        this.successToast('Salvo com Sucesso!');
        window.history.back();
      } catch (error) {
        this.errorToast('Erro ao Salvar!');
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
