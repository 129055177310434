import api from '@/services/api';

const url = 'academic';

const create = academic => api.post(`${url}/create-academic`, academic);

const read = () => api.get(`${url}/read-academic`);

const readById = id => api.get(`${url}/read-academic/${id}`);

const update = academic => api.put(`${url}/update-academic`, academic)

export default { create, read, readById, update };
