import Vue from 'vue';
import dayjs from 'dayjs';
import mask from '../utils/mask';

Vue.filter('date', date =>
  date === null ? 'Indeterminado' : dayjs(date).format('DD/MM/YYYY'),
);

Vue.filter(
  'hour',
  date => `${dayjs(date).format('HH')}h${dayjs(date).format('mm')}`,
);

Vue.filter('trim', (value, size) => {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return `${value.substr(0, size)}...`;
});

Vue.filter('cpf', value => mask.cpfMask(value));
