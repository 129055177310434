<template>
  <v-data-table
    :headers="headers"
    :items="internshipLocal.academic"
    class="elevation-1"
    disable-sort
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Lista de Acadêmicos</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="loadAcademics()"
            >
              Editar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">Selecionar Acadêmico</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-card>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="academicLoads"
                    :search="search"
                    v-model="internshipLocal.academic"
                    item-key="id"
                    show-select
                    class="elevation-1"
                  ></v-data-table>
                </v-card>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Você tem certeza que deseja deletar este item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Deletar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import academicService from '../../services/academic';

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: '',
    selected: [],
    headers: [
      {
        text: 'Nome do Acadêmico',
        align: 'start',
        value: 'person.name',
      },
      { text: 'CPF', value: 'person.cpf' },
    ],
    fields: [],
    academicLoads: [],
    editedIndex: -1,
  }),

  props: {
    internshipId: {
      type: Number,
      required: true,
      default: () => -1,
    },
    internship: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    internshipLocal: {
      get() {
        return this.internship;
      },
      set(valor) {
        this.$emit('update:internship', valor);
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async loadAcademics() {
      try {
        const { data } = await academicService.read();
        this.academicLoads = data;
      } catch (error) {
        console.error(error);
      }
    },

    async initialize() {
      // todo: academics aready selected
      try {
        this.fields = [];
      } catch (error) {
        console.error(error);
      }
    },

    editItem(item) {
      this.editedIndex = this.fields.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.fields.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.fields.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.fields[this.editedIndex], this.editedItem);
      } else {
        this.fields.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
