<template>
  <div>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="selectItems"
        :sort-by="['id']"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-autocomplete
              class="autoComplete"
              :items="yearSelect"
              v-model="value"
              full-width
              dense
              solo-inverted
              @change="getYearFilter(value, items)"
            ></v-autocomplete>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              :to="{ name: `planos-de-estagio.cadastro` }"
            >
              Adicionar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click.prevent="editItem(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click.prevent="downloadInternshipReport(item.id, item.class)"
                v-bind="attrs"
                v-on="on"
                >mdi-download</v-icon
              >
            </template>
            <span>Baixar</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
          <span>{{ item.startDate | date }}</span>
        </template>
      </v-data-table>
    </v-container>

    <internship-more-details
      v-if="visible"
      @close="visible = !visible"
      :dialog="visible"
      :id="subjectId"
    />
  </div>
</template>

<script>
import internshipService from '../../services/internship';
import internshipMixin from '../../mixin/internship/internship-mixin';
import InternshipMoreDetails from '../Internship/InternshipMoreDetails';

export default {
  name: 'SimpleList',
  mixins: [internshipMixin],
  components: { InternshipMoreDetails },

  data: () => ({
    search: '',
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Curso', value: 'course' },
      { text: 'Classe', value: 'class' },
      { text: 'Semestre', value: 'semester' },
      { text: 'Data de Início', value: 'startDate' },
      { text: 'Ações', value: 'actions' },
    ],
    yearSelect: [],
    selectedYear: 2022,
    selectItems: [],
    value: null,
    year: 0,
  }),

  async created() {
    await this.loadItemList();
    this.getYearList();
    this.getYearFilter(this.year, this.items);
  },

  methods: {
    async loadItemList() {
      await this.findItems(internshipService.read);
    },
    async downloadInternshipReport(internshipId, internshipClass) {
      try {
        const { data } = await internshipService.downloadInternshipCSVReport(
          internshipId,
        );

        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${internshipClass}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.successToast('Gerado com Sucesso!');
      } catch (error) {
        this.errorToast('Erro ao Gerar Arquivo!');
      }
    },
    getYearList() {
      const today = new Date();
      this.year = today.getFullYear();
      this.value = this.year;
      let yearLimit = 2021;
      while (yearLimit < this.year) {
        const addYear = yearLimit + 1;
        this.yearSelect.push(addYear);
        yearLimit++;
      }
    },

    getYearFilter(select, items) {
      this.selectItems = [];
      for (const i in items) {
        if (items[i].startDate.startsWith(select)) {
          this.selectItems.push(items[i]);
        }
      }
    },
  },
};
</script>

<style scoped>
.autoComplete {
  max-width: 10%;
  align-items: left;
  min-width: 10%;
}
</style>
