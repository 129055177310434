<template>
  <div>
    <div v-if="showChangePassword" class="d-flex justify-center align-center">
      <v-card elevation="18" class="pa-5" width="500">
        <h4>Coloque sua nova senha:</h4>
        <br />
        <v-form ref="changePasswordForm">
          <v-row
            v-for="field in changePasswordFields"
            :key="field.label"
            no-gutters
            justify="center"
          >
            <v-col>
              <v-text-field
                v-model="changePassword[field.prop]"
                :label="field.label"
                :type="field.type"
                :rules="field.rules"
                outlined
              />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-btn
              width="450"
              color="#2F3783"
              class="white--text"
              v-if="isSubmitting"
              disabled
              @click="submit"
              ><v-icon v-if="submitSuccess">mdi-check-bold</v-icon>
              <img
                v-else
                width="50px"
                alt="Enviando"
                src="../../assets/loading-circle-blue.svg"
            /></v-btn>
            <v-btn
              width="450"
              color="#2F3783"
              class="white--text"
              v-else
              @click="submit"
              >Enviar</v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </div>
    <div v-if="hideChangePassword" class="d-flex justify-center align-center">
      <v-card elevation="18" class="pa-5" width="500">
        <h4>
          Por favor, ocorreu um erro com a validação do seu token, coloque seu
          e-mail e tente novamente:
        </h4>
        <br />
        <v-row no-gutters justify="center">
          <v-btn
            width="450"
            color="#2F3783"
            class="white--text"
            :to="{ name: 'auth.forgotPassword' }"
            >Reenviar e-mail</v-btn
          >
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import usersResetPassword from '@/services/users';

export default {
  name: 'ResetPassword',
  data() {
    return {
      changePassword: {
        password: '',
        passwordConfirmation: '',
      },
      changePasswordFields: [
        {
          label: 'Nova senha',
          type: 'password',
          prop: 'password',
          rules: [this.$rules.password],
        },
        {
          label: 'Confirme a nova senha',
          type: 'password',
          prop: 'passwordConfirmation',
          rules: [
            () =>
              this.changePassword.password ===
                this.changePassword.passwordConfirmation || 'Senhas diferentes',
          ],
        },
      ],
      passwordChanged: false,
      showChangePassword: false,
      hideChangePassword: false,
      isSubmitting: false,
      submitSuccess: false,
    };
  },
  async created() {
    try {
      const { data } = await usersResetPassword.validateResetPassword(
        this.$route.params.token,
      );
      if (data.validLink === true) {
        this.showChangePassword = true;
      }
    } catch (error) {
      this.hideChangePassword = true;
    }
  },
  methods: {
    async submit() {
      try {
        if (!this.$refs.changePasswordForm.validate()) return;
        this.isSubmitting = true;
        await usersResetPassword.usersResetPassword({
          password: this.changePassword.password,
          token: this.$route.params.token,
        });
        this.successToast('Senha alterada com Sucesso!');
        this.submitSuccess = true;
      } catch (error) {
        this.errorToast('Erro ao salvar nova senha');
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped></style>
