<template>
  <div>
    <strong>Cadastro</strong>
    <sector-form ref="SectorForm" :sector.sync="sector" />

    <v-row class="pa-5">
      <v-col class="d-flex justify-center" cols="12">
        <v-btn
          x-large
          :loading="false"
          color="secondary"
          class="ma-2 white--text"
          @click="$router.go(-1)"
        >
          Cancelar
        </v-btn>
        <v-btn
          x-large
          :loading="false"
          color="green"
          class="ma-2 white--text"
          @click="submitFormSector"
        >
          Salvar
          <v-icon right dark> mdi-check-bold </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectorForm from '@/components/Form/SectorForm';
import sectorService from '@/services/sector.js';

export default {
  name: 'FormSector',
  components: { SectorForm },

  data() {
    return {
      sector: {
        title: '',
        sectorField: '',
        maxAcademics: '',
        description: '',
      },
    };
  },

  methods: {
    async submitFormSector() {
      if (!this.$refs.SectorForm.requestBaseFormValidation()) {
        this.errorToast('Verifique os campos obrigatórios');
        return;
      }
      try {
        const sector = { ...this.sector };

        await sectorService.create({
          sector,
        });
        this.successToast('Salvo com Sucesso!');
        window.history.back();
      } catch (error) {
        this.errorToast('Erro ao Salvar!');
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
