<template>
  <base-form ref="baseForm" :fields="fields" form-name="person">
    <template v-slot="{ field }">
      <div v-if="!field.select">
        <div v-if="field.mask">
          <v-text-field
            :label="field.label"
            v-model="personLocal[field.prop]"
            v-mask="field.mask"
            :counter="field.maxLength || false"
            :maxLength="field.maxLength || false"
            :rules="field.rules"
            :readonly="isViewOnlyMode"
            @input="uppercase(field.prop, $event)"
            outlined
          />
        </div>
        <div v-else>
          <v-text-field
            :label="field.label"
            v-model="personLocal[field.prop]"
            :maxLength="field.maxLength || false"
            :rules="field.rules"
            :counter="field.maxLength || false"
            :readonly="isViewOnlyMode"
            @input="uppercase(field.prop, $event)"
            outlined
          />
        </div>
      </div>
      <div v-else>
        <v-select
          :items="field.item"
          v-model="personLocal[field.prop]"
          :label="field.label"
          :rules="field.rules"
          :readonly="isViewOnlyMode"
          outlined
        ></v-select>
      </div>
    </template>
  </base-form>
</template>

<script>
import BaseForm from '@/components/Form/BaseForm';
import civilStateFieldEnum from '@/enums/civilStateFieldEnum';
import dayjs from 'dayjs';

export default {
  name: 'PersonForm',
  components: { BaseForm },
  props: {
    person: {
      type: Object,
      default: () => {},
    },
    editForm: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    personLocal: {
      get() {
        return this.person;
      },
      set(valor) {
        this.$emit('update:person', valor);
      },
    },
  },
  data() {
    return {
      isViewOnlyMode: false,
      fields: [
        {
          label: 'Nome Completo',
          prop: 'name',
          rules: [this.$rules.required],
          cols: '12',
          maxLength: 150,
        },
        {
          label: 'Sexo',
          prop: 'sexo',
          item: ['Masculino', 'Feminino'],
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '6',
        },
        {
          label: 'Data de Nascimento',
          mask: '##/##/####',
          prop: 'bornDate',
          rules: [this.$rules.required],
          cols: '6',
        },
        {
          label: 'Estado Civil',
          prop: 'civilState',
          item: civilStateFieldEnum,
          rules: [this.$rules.requiredSelect],
          select: true,
          cols: '12',
        },
        {
          label: 'Telefone Residencial',
          mask: '(##) ####-####',
          prop: 'homePhone',
          cols: '6',
          maxLength: 14,
        },
        {
          label: 'Telefone Celular',
          mask: '(##) #####-####',
          prop: 'mobilePhone',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 15,
        },
        {
          label: 'E-mail',
          prop: 'email',
          rules: [this.$rules.required, this.$rules.email],
          cols: '12',
          maxLength: 100,
        },
        {
          label: 'CPF',
          mask: '###.###.###-##',
          prop: 'cpf',
          rules: [this.$rules.required, this.$rules.validCpf],
          cols: '6',
          maxLength: 14,
        },
        {
          label: 'RG',
          mask: '###############',
          prop: 'rg',
          rules: [this.$rules.required],
          cols: '6',
          maxLength: 15,
        },
      ],
    };
  },
  created() {
    if (this.person.id) {
      this.isViewOnlyMode = !this.editForm;
    }
    if (this.personLocal.bornDate)
      this.personLocal.bornDate = dayjs(this.personLocal.bornDate).format(
        'DD/MM/YYYY',
      );
  },

  methods: {
    requestBaseFormValidation() {
      return this.$refs.baseForm.validateBaseForm();
    },
    uppercase(prop, e) {
      this.personLocal[prop] = e.toUpperCase();
    },
  },
};
</script>
