<template>
  <div>
    <div class="d-flex justify-center align-center">
      <v-card elevation="18" class="pa-5" width="500">
        <h4>{{ result }}</h4>
        <br />
        <v-row no-gutters justify="center">
          <v-btn
            width="450"
            color="#2F3783"
            class="white--text"
            :to="{ name: 'auth.login' }"
            >Voltar a tela inicial</v-btn
          >
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import ServiceUser from '@/services/users';
export default {
  name: 'ConfirmEmail',
  data() {
    return {
      result: null,
    };
  },
  async created() {
    try {
      const { data } = await ServiceUser.validateUser(this.$route.params.token);
      this.result = data.message;
    } catch (error) {
      this.result = error.response.data.message;
    }
  },
};
</script>

<style scoped></style>
