<template>
  <div>
    <strong>Cadastro</strong>
    <internship-form ref="internshipForm" :internship.sync="internship" />

    <v-row class="pa-5">
      <v-col class="d-flex justify-center">
        <v-btn
          x-large
          :loading="false"
          color="secondary"
          class="ma-2 white--text"
          @click="$router.go(-1)"
        >
          Cancelar
        </v-btn>
        <v-btn
          x-large
          :loading="false"
          color="green"
          class="ma-2 white--text"
          @click="submitFormInternship"
        >
          Salvar
          <v-icon right dark> mdi-check-bold </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import moment from 'moment';
import InternshipForm from '@/components/Form/InternshipForm';
import internshipService from '@/services/internship';

export default {
  name: 'FormInternship',
  components: { InternshipForm },

  data() {
    return {
      internship: {
        workingHours: '',
        startHours: '',
        endHours: '',
        startDate: '',
        endDate: '',
        class: '',
        course: '',
        semester: '',
        supervisor: '',
        sector: '',
      },
    };
  },

  methods: {
    async submitFormInternship() {
      if (!this.$refs.internshipForm.requestBaseFormValidation()) {
        this.errorToast('Verifique os campos obrigatórios');
        return;
      }

      try {
        // todo: usar promisse.all para paralelisar as requisicoes
        const internship = { ...this.internship };

        const myStartDateSplitted = internship.startDate.split('/');
        const myEndDateSplitted = internship.endDate.split('/');
        internship.startHours = moment(internship.startHours, 'HHmm').format(
          'HH:mm',
        );
        internship.endHours = moment(internship.endHours, 'HHmm').format(
          'HH:mm',
        );

        internship.workingHours = parseInt(internship.workingHours);
        internship.startDate = dayjs(
          `${myStartDateSplitted[2]}/${myStartDateSplitted[1]}/${myStartDateSplitted[0]}`,
        );
        internship.endDate = dayjs(
          `${myEndDateSplitted[2]}/${myEndDateSplitted[1]}/${myEndDateSplitted[0]}`,
        );

        await internshipService.create(internship);
        this.successToast('Salvo com Sucesso!');
        window.history.back();
      } catch (error) {
        this.errorToast('Erro ao Salvar!');
      }
    },
  },
};
</script>

<style scoped></style>
